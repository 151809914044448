import { useState } from 'react'
import {motion } from 'framer-motion'
import { BiChevronDown} from "react-icons/bi";

function AccorItem({question,answer}) {
    const [toggleFlag, setToggleFlag] = useState(false)
    const handleItemClick =()=>{
        setToggleFlag(!toggleFlag)
    }

  return (
    <div className="accordian-items" >
        <div className="question" onClick={(e)=>handleItemClick()}>
            
            <h3>
                {question}
            </h3>

            <div className={`view-btn ${toggleFlag?"up-btn":""}`}><BiChevronDown /></div>
        </div>
        
        <div 
            className={` ${toggleFlag?`answer`:`answer-inactive`}`}>
            {toggleFlag && answer.map((ans,i)=>
                <span key={i}
                >
                    {/* <AnimatePresence> */}
                    <motion.p
                        initial={{opacity:0,  x:`-10px`}}
                        animate={{opacity:1, x:0}}
                        transition={{duration:0.2}}    
                        >
                        {ans}
                    </motion.p>
            {/* </AnimatePresence> */}
                    <br />
                </span>
            )}
        </div>
    </div>
  )
}

export default AccorItem