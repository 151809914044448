import {useEffect, useState} from 'react'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom';
import "./Navbar.css"

function Navbar() {
    const navigate = useNavigate();

    const isSmallWindow = useMediaQuery({ query: '(max-width: 768px)' }) 

    const iconLink = isSmallWindow?"https://ik.imagekit.io/qljqw3tvn/NeoTeak/Neoteak_Logo_Colour_k1-WfXXH6.png":"https://ik.imagekit.io/qljqw3tvn/NeoTeak/NeoTeak_logo-removebg-preview_BiW_xP3sS.png?updatedAt=1682601496023"
    
    const [scrollDir, setScrollDir] = useState("up");
    const [offset, setOffset] = useState(0);

    // const handleContact=()=>{
        // navigate("/")
        // window.scrollTo({top:win3Ref.current.offsetTop, behavior:"smooth"})
    // }

    useEffect(() => {
        const onScroll = () => setOffset(window.scrollY);
        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    useEffect(() => {
        const threshold = 0;
        let lastScrollY = window.scrollY;
        let ticking = false;

        const updateScrollDir = () => {
        const scrollY = window.scrollY;

        if (Math.abs(scrollY - lastScrollY) < threshold) {
            ticking = false;
            return;
        }
        setScrollDir(scrollY > lastScrollY ? "down" : "up");
        lastScrollY = scrollY > 0 ? scrollY : 0;
        ticking = false;
        };

        const onScroll = () => {
        if (!ticking) {
            window.requestAnimationFrame(updateScrollDir);
            ticking = true;
        }
        };

        window.addEventListener("scroll", onScroll);
        // console.log(scrollDir);

        return () => window.removeEventListener("scroll", onScroll);
    }, [scrollDir]);

    return (
    <nav className={`nav-container ${ (scrollDir==="up" || offset<100) ? "":"hidden" }`}>
        
        {/* <a href="/"> */}
            <img className='img-test' 
                src={iconLink} 
                alt="NeoTeak" 
                onClick={()=>navigate("/")}
            />
        {/* </a> */}
    
        <div className="nav-btns">
            {/* <a href="/about-us"> */}
                <h2 
                    onClick={()=>navigate("/about-us")}
                >
                    About Us
                </h2>
            {/* </a> */}

            {/* <a href="/products/plywood"> */}
                <h2 
                onClick={()=>navigate("/products/plywood")}
                >
                    Products
                </h2>
            {/* </a> */}

            {/* <a href="/know-your-ply/plywood"> */}
                <h2 
                onClick={()=>navigate("/know-your-ply/plywood")}
                >
                    Know your Ply
                </h2>
            {/* </a> */}

            {/* <a href="/channel-partners">     */}
                <h2 
                onClick={()=>navigate("/channel-partners")}
                >   
                    Channel Partners
                </h2>
            {/* </a> */}
        </div>

    </nav>
  )
}

export default Navbar