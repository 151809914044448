import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { motion } from 'framer-motion'
import { FiChevronLeft, FiChevronRight} from "react-icons/fi";
import { AiOutlineColumnHeight} from "react-icons/ai";
import { BsClipboard2Check, BsCheckLg } from "react-icons/bs";

import ProdDescList from '../../components/Lists/ProdDescList';
import ProdCategoryList from '../../components/Lists/ProdCategoryList';
import ProdIndex from '../../components/ProdIndex/ProdIndex';

function ProductSlider({renderFlag}) {
  const navigate = useNavigate();
  const params = useParams()

  const [current, setCurrent] = useState(0);
  const [slugCheckBool, setSlugCheckBool] = useState(false);
  const [renderAnimationFlag, setRenderAnimationFlag ] = useState(false);

  const catList = ProdCategoryList.map((item)=>item.categorySlug)

  const tempList = ProdDescList.filter((prod)=>prod.type===params.prodCategory)
  
  const filteredProdDesc = tempList.map((prod)=>{
    if(prod.imgSrc){

      let temp1 = prod.imgSrc.substring(0,32)
      let temp2 = prod.imgSrc.substring(42, prod.imgSrc.length)
      let srcSetLinks = temp1+"/tr:w-200"+temp2+" 400w,"+ temp1+"/tr:w-600"+temp2+" 800w,"+ temp1+"/tr:w-800"+temp2+" 1200w"
      
      return{
        ...prod,
        srcSet: srcSetLinks
      }
    }
    else{
      return{
        ...prod
      }
    }
  })

  const indexList = filteredProdDesc.map((prod)=>{
    let obj = {}
    if(prod.specs){
      obj.specs = {list:[]}
      obj.specs.list = prod.specs
    }
    if(prod.specInfo){
      obj.specs.info = prod.specInfo
    }
    if(prod.applications){
      obj.applications = {list:[]}
      obj.applications.list = prod.applications
    }
    if(prod.applicationInfo){
      obj.applications.info = prod.applicationInfo
    }
    if(prod.sizes){
      obj.sizes = {list:[]}
      obj.sizes.list = prod.sizes
    }
    if(prod.sizeInfo){
      obj.sizes.info = prod.sizeInfo
    }
    return obj
  })
  const windowLength = filteredProdDesc.length>0?ProdDescList.filter((prod)=>prod.type===params.prodCategory).length:1;
  const prodCat = ProdCategoryList.filter((item)=>item.categorySlug===params.prodCategory)[0]

  const prevSlide=()=>{
    setCurrent(current === 0 ? windowLength-1: current-1)
  }

  const nextSlide=()=>{
      setCurrent(current === windowLength -1 ? 0: current+1)
  }


  useEffect(()=>{
    setCurrent(0)
    let slugCheck = catList.includes(params.prodCategory)
    setSlugCheckBool(slugCheck)

    setRenderAnimationFlag(true)
    setTimeout(()=>{
      setRenderAnimationFlag(false)
    },500)

    console.log(`fL:${JSON.stringify(filteredProdDesc,null,4)}`)

  },[params.prodCategory])

  return (
    <>
    {slugCheckBool &&
    <>
    <div className="vertical-text" style={{minHeight:`30vh`}}>
        <div className='small-header'>
          <p>Neoteak's</p>
        </div>
        <div className={`text`} style={{width:`fit-content`}}
        >  
          {prodCat.categoryName}
        </div>

        <div className="description" >
          {prodCat.categoryDesc}
        </div>

        <div className={`know-more `} >
          <p>Confused on which {prodCat.categoryName.toLowerCase()} is the one for you?</p>
          <div className={`hover-underline-animation-btn alt-btn ${renderFlag?"render-div":""}`}
            onClick={()=>navigate("/know-your-ply/"+prodCat.categorySlug)}
          >
            Know your {prodCat.categoryName}
          </div>
        </div>

    </div>

    <div className="vertical-text" style={{flexDirection:`column`}}>
      <div className="prod-slider-container">
        
      {filteredProdDesc.map((prod,index)=>{
        return(

        
        <motion.div className={`prod-slider-wrapper ${index===current?"":"prod-slider-hidden"}`} 
          key={index}  
          initial={{opacity:0}}
          animate={{opacity:1}}
          transition={{duration:0.4}} 
        >
          
          <div className="product-item">
            
          {prod.imgSrc && 
            <div className={`prod-img ${renderFlag?"render-div":""}`}>
              <img src={prod.imgSrc}
                srcSet={prod.srcSet}
                sizes="(max-width: 400px) 400px, 800px, 1200px"
                alt={prod.name} />
            </div>}

            <div className="prod-desc-container">
              <div >
                  <div className="prod-name">Neoteak {prod.btn && <span style={{color:`${prod.iconColor}`}}>{prod.btn}</span>}</div>
                  <div className="prod-desc">{prod.desc}</div>
                  <div className="prod-sizes">
                    
                      {prod.sizes &&
                        <AiOutlineColumnHeight className='prod-icon size-icon' style={{backgroundColor:prod.iconColor}}/>
                      }

                      {prod.sizes?.map((size,indexSize)=>
                          <p key={indexSize}>{size}</p>
                      )}
                  </div>
              </div>

              <div className="prod-spec-container">

                  {prod.specs && 
                <div className="prod-specs">
                    <BsClipboard2Check className='prod-icon ' style={{backgroundColor:prod.iconColor}}/>
                  {prod.specs?.map((spec,indexSpec)=>
                    <p key={indexSpec}>{spec}</p>
                    )}
                </div>
                  }

                <div className="prod-applications">
                  {prod.applications &&
                    <BsCheckLg className='prod-icon check' 
                    style={{backgroundColor:prod.iconColor}}/>}
                  
                  {prod.applications?.map((application,indexApplication)=>
                      <p key={indexApplication}>{application}</p>
                  )}
                </div>

                    
              </div>

              {/* Empty Div */}
              <div></div>

              {/* Slider Nav Btns */}
              {windowLength>1 &&
                <div className='prod-cat-nav-btns'>  
                
                <div className="slider-icon left-icon">

                  <FiChevronLeft
                      onClick={()=>prevSlide()}
                  />
                </div>

                <div className='slider-icon right-icon'> 
                    
                  <FiChevronRight
                    onClick={()=>nextSlide()}
                    />

                </div> 
              </div>}

            </div>
          </div>  
                
          {/* Prod index */}
          <div className="product-item application-slider">
            <ProdIndex indexList={indexList[index]} colour={prod.iconColor}/>
          </div>
          
        </motion.div>
          )
      }
      )}
      </div>
      
    </div>
    
    </>
    }

    </>
  )
}

export default ProductSlider