import {useState, useEffect, useRef} from 'react'
import "./Products.css"
import { Outlet, useNavigate,  useParams  } from 'react-router-dom';
import {motion} from 'framer-motion'

import ProdCategoryList from '../../components/Lists/ProdCategoryList';
// import ProductSection from './ProductSection';

import Contact from "../../components/Contact/Contact"
import LoadingTransition from '../../components/Loading/LoadingTransition';
import Footer from '../../components/Footer/Footer';
import NotFound from '../NotFound/NotFound';

function Products({passRenderFlag}) {
  const navigate = useNavigate();
  const params = useParams()

  const [offset, setOffset] = useState(0);

  const productRef = useRef();
  const [product_offset, setProduct_offset] = useState()
  const [product_diff, setProduct_diff] = useState()

  const contactRef = useRef();
  const [contact_offset, setContact_offset] = useState()
  const [contact_diff, setContact_diff] = useState()
  
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  const [loadingFlag, setLoadingFlag] = useState(false)

  const [slugCheckBool, setSlugCheckBool] = useState(false)

  const [renderFlag, setRenderFlag] = useState(false)  

  const catList = ProdCategoryList.map((item)=>item.categorySlug)
  const prodSlug = catList.filter(item=>item===params.prodCategory).length>0?catList.filter(item=>item===params.prodCategory)[0 ]:""
  
  ////////////////////////////////////
  //////////Fucntions/////////////////
  ////////////////////////////////////

  //Get Ref Position relative to window
  const getPosition=()=>{
    if(prodSlug!==""){
      let product = productRef.current.offsetTop
      let contact = contactRef.current.offsetTop
      setProduct_offset(product)
      setContact_offset(contact)
      }
    }

  //Handle product navigation
  const handleProdNavigation=(prodPath)=>{
    if(!(params.prodCategory===prodPath)){
      passRenderFlag(true)
      setRenderFlag(true)
    }
    setTimeout(()=>{
      passRenderFlag(false)
      setRenderFlag(false)
      navigate(prodPath)
    },300)
  }

  ////////////////////////////////////
  //////////Hooks/////////////////////
  ////////////////////////////////////

  //Get Screen Height
  useEffect(() => {
      const updateDimension = () => {
          setScreenHeight(window.innerHeight)
          // console.log(`window.innerHeight:${window.innerHeight}, buffer:${window.innerHeight/10}`)
      }
      window.addEventListener('resize', updateDimension);
  
  
      return(() => {
          window.removeEventListener('resize', updateDimension);
      })
  }, [screenHeight])

  //Get Scroll Page Offset
  useEffect(() => {
      getPosition()        

      const onScroll = () => setOffset(window.scrollY);
      // clean up code
      window.removeEventListener('scroll', onScroll);
      window.addEventListener('scroll', onScroll, { passive: true });
      return () => window.removeEventListener('scroll', onScroll);
  }, []);

  //Update Ref Positions on window Resize and Page Load Animation
  useEffect(() => {
    window.addEventListener("resize", getPosition);
    
    setLoadingFlag(true)
    
    setTimeout(()=>{
      setLoadingFlag(false)
    },2100)
    return ()=> window.removeEventListener("resize",getPosition)
  }, []);

  
  //Process and update Ref for CSS
  useEffect(()=>{
      if(prodSlug!==""){
      let diff1 = product_offset 
      let diffContact = contact_offset
      let winHeight_20= screenHeight/10
      diff1 = (product_offset>=offset+winHeight_20?0:offset-product_offset+winHeight_20)
      diffContact = (contact_offset>=offset+winHeight_20?0:offset-contact_offset+winHeight_20)
      setProduct_diff(diff1)
      setContact_diff(diffContact)
    }
    // console.log(`scrollProdOffset:${offset}`)
    
    // console.log(`diff:${diffContact}`)
  },[offset])
  
  //Check for Product Slug
  useEffect(()=>{
    let slugCheck = catList.includes(params.prodCategory)
    setSlugCheckBool(slugCheck)    
  },[params.prodCategory])

  return (
    <>
    {loadingFlag? <LoadingTransition />:
    <>
    <motion.div className='content-container window-container'ref={productRef}
      initial={{opacity:0}}
      animate={{opacity:1}}
      transition={{duration:0.4,delay:0.2}}
    >
      <div className="window-content" >

        <h1 
          style={offset>(screenHeight*3/10)?{transform:`translateY(0px)`,opacity:`0`}:{transform: `translateY(${offset*0.6}px)`, opacity:`${((screenHeight*3/10)-offset)/((screenHeight*3/10)+offset)}`}}
        >Our Products</h1>
      </div>
    </motion.div>
   

    <motion.div className="content-container landing-container product-container" 
      initial={{opacity:0, y:"50px"}}
      animate={{opacity:1, y:"0px"}}
      transition={{duration:0.5}} 
    >
        <div className='prod-nav-container'>
          {ProdCategoryList.map((item,index)=>
            <div className='prod-nav-btns' key={index}
              style={params.prodCategory===item.categorySlug?{color:`var(--templateContrast)`}:{}}
              onClick={()=>handleProdNavigation(item.categorySlug)}
            >
              {item.categoryName}
            </div>
          )}
        </div>
        
        <div className="content-grid product-slider">
          <Outlet renderFlag={renderFlag}/>
        </div>
    </motion.div>

    <div className="content-container landing-container alt-container" 
        style={{backgroundColor:`transparent`}} 
        ref={contactRef}
        >
      <Contact elemDiff={contact_diff} screenHeightConst={(screenHeight/20+1)} />
    </div>

    <Footer />
  </>
  }
  </>
  )
}

export default Products