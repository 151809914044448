import {useState, useEffect, useRef} from 'react'
// import { useNavigate } from 'react-router-dom';
import {motion} from 'framer-motion'
import "./AboutUs.css"

import Contact from "../../components/Contact/Contact"
// import FAQTransition from '../../components/Loading/FAQTransition';
import LoadingTransition from '../../components/Loading/LoadingTransition';
import Footer from '../../components/Footer/Footer';

function AboutUs() {
  // const navigate = useNavigate();
  const cover_imgSrc = "https://ik.imagekit.io/qljqw3tvn/tr:w-1800/NeoTeak/pexels-quintin-gellar-313773_7k5RdN7vI.jpg" 
  const cover_srcSet = cover_imgSrc.substring(0,32)+"/tr:w-400"+cover_imgSrc.substring(42, cover_imgSrc.length)+" 400w,"+
                       cover_imgSrc.substring(0,32)+"/tr:w-800"+cover_imgSrc.substring(42, cover_imgSrc.length)+" 800w,"+
                       cover_imgSrc.substring(0,32)+"/tr:w-1800"+cover_imgSrc.substring(42, cover_imgSrc.length)+" 1200w,"
  
  const sourcing_imgSrc = "https://ik.imagekit.io/qljqw3tvn/tr:w-1200/NeoTeak/tree-trunks-ready-be-cut_sr48vOYi5U.jpg?updatedAt=1695129110507" 
  const sourcing_srcSet = sourcing_imgSrc.substring(0,32)+"/tr:w-400"+sourcing_imgSrc.substring(42, sourcing_imgSrc.length)+" 400w,"+
                      sourcing_imgSrc.substring(0,32)+"/tr:w-800"+sourcing_imgSrc.substring(42, sourcing_imgSrc.length)+" 800w,"+
                      sourcing_imgSrc.substring(0,32)+"/tr:w-1200"+sourcing_imgSrc.substring(42, sourcing_imgSrc.length)+" 1200w,"
 
  const crafstmanship_imgSrc = "https://ik.imagekit.io/qljqw3tvn/tr:w-1200/NeoTeak/wood-cutting_nVFy83j6_.jpg" 
  const crafstmanship_srcSet = crafstmanship_imgSrc.substring(0,32)+"/tr:w-400"+crafstmanship_imgSrc.substring(42, crafstmanship_imgSrc.length)+" 400w,"+
                      crafstmanship_imgSrc.substring(0,32)+"/tr:w-800"+crafstmanship_imgSrc.substring(42, crafstmanship_imgSrc.length)+" 800w,"+
                      crafstmanship_imgSrc.substring(0,32)+"/tr:w-1200"+crafstmanship_imgSrc.substring(42, crafstmanship_imgSrc.length)+" 1200w,"

  const finalStep_imgSrc = "https://ik.imagekit.io/qljqw3tvn/tr:w-1200/NeoTeak/stack-wooden-planks-workshop%20(1)_9J0vMCe542.jpg" 
  const finalStep_srcSet = finalStep_imgSrc.substring(0,32)+"/tr:w-400"+finalStep_imgSrc.substring(42, finalStep_imgSrc.length)+" 400w,"+
                      finalStep_imgSrc.substring(0,32)+"/tr:w-800"+finalStep_imgSrc.substring(42, finalStep_imgSrc.length)+" 800w,"+
                      finalStep_imgSrc.substring(0,32)+"/tr:w-1200"+finalStep_imgSrc.substring(42, finalStep_imgSrc.length)+" 1200w,"
  

  const [offset, setOffset] = useState(0);

  const aboutRef = useRef();
  const [about_offset, setAbout_offset] = useState()
  const [about_diff, setAbout_diff] = useState()

  const contactRef = useRef();
  const [contact_offset, setContact_offset] = useState()
  const [contact_diff, setContact_diff] = useState()
  
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  const [loadingFlag, setLoadingFlag] = useState(false)
  
  ////////////////////////////////////
  //////////Fucntions/////////////////
  ////////////////////////////////////

  //Get Ref Positoin relative to window
  const getPosition=()=>{
    let about = aboutRef.current.offsetTop
    let contact = contactRef.current.offsetTop
    setAbout_offset(about)
    setContact_offset(contact)
    }

  ////////////////////////////////////
  //////////Hooks/////////////////////
  ////////////////////////////////////

  //Get Screen Height
  useEffect(() => {
      const updateDimension = () => {
          setScreenHeight(window.innerHeight)
          // console.log(`window.innerHeight:${window.innerHeight}, buffer:${window.innerHeight/10}`)
      }
      window.addEventListener('resize', updateDimension);
  
  
      return(() => {
          window.removeEventListener('resize', updateDimension);
      })
  }, [screenHeight])

  //Get Scroll Page Offset
  useEffect(() => {
      getPosition()        

      const onScroll = () => setOffset(window.pageYOffset);
      // clean up code
      window.removeEventListener('scroll', onScroll);
      window.addEventListener('scroll', onScroll, { passive: true });
      return () => window.removeEventListener('scroll', onScroll);
  }, []);

  //Update Ref Positions on window Resize and Page Load Animation
  useEffect(() => {
    window.addEventListener("resize", getPosition);

    setLoadingFlag(true)
    
    setTimeout(()=>{
      setLoadingFlag(false)
    },2100)
    window.removeEventListener("resize", getPosition)
  }, []);

  //Process and update Ref for CSS
  useEffect(()=>{
    let diff1 = about_offset 
    let diffContact = contact_offset
    let winHeight_20= screenHeight/10
    diff1 = (about_offset>=offset+winHeight_20?0:offset-about_offset+winHeight_20)
    diffContact = (contact_offset>=offset+winHeight_20?0:offset-contact_offset+winHeight_20)
    setAbout_diff(diff1)
    setContact_diff(diffContact)

    // console.log(`diff:${diffContact}`)
  },[offset])
  
  return (
    <>
    {loadingFlag? <LoadingTransition />:
    <>
    <motion.div className='content-container window-container'ref={aboutRef}
      initial={{opacity:0}}
      animate={{opacity:1}}
      transition={{duration:0.4,delay:0.2}}
    >
      <div className="window-content" >

        <h1 
          style={offset>(screenHeight*3/10)?{transform:`translateY(0px)`,opacity:`0`}:{transform: `translateY(${offset*0.6}px)`, opacity:`${((screenHeight*3/10)-offset)/((screenHeight*3/10)+offset)}`}}
        >Our Story</h1>
      </div>
    </motion.div>

    <motion.div className="content-container landing-container"
      initial={{opacity:0, y:"50px"}}
      animate={{opacity:1, y:"0px"}}
      transition={{duration:0.5}} 
    >
      
      <div className="about-content-grid">
        
        
        <div className="content-img center-full-img" style={{gridColumn:`1/ span 2`,borderRadius:`25px 25px 0 0`}}>
          <img
            src={cover_imgSrc} 
            srcSet={cover_srcSet}
            sizes="(max-width: 400px) 400px, (max-width: 768px) 800px, 1200px"
            alt="bg" 
            style={{borderRadius:`25px 25px 0 0`, filter:`grayscale(100%)`}} 
          />
        </div>
    
        {/* Empty Div */}
        <div></div>

        {/* Header Paragraph */}
        <article className='about-text right-side-text'>

          <section className='big-about'>

            <p>
            Neoteak is a venture with over 55 years of service in the Plywood Industry. From our workshop floors to our customers' decor we only have one aim. To be the benchmark of 
            <span style={{fontWeight:"bold"}}> Quality, Reliability and Sustainiblity.</span>
            </p>

          </section>
        </article>


        {/* Empty Div */}
        <article className="about-text left-side-text">
          <section className="big-about">

            <h1>Sourcing</h1>
          </section>
        </article>

        {/* Paragraph 1 */}
        <article className='about-text right-side-text'>

          <section className='medium-about'>
            <p>
            Journey of a Neoteak Product starts from the highest quality wood. Experts pick and choose the finest raw materials to meet the Neoteak standards. 
            </p>
            <p>
            We understand our impact on the enviornment and ensure our operations follow sustainable and enviorment-friendly practices.
            </p>
            <br />
            <p>
            The rest is simple. If it's not upto the mark then it does not end up in your home.
            </p>

            
          </section>
        </article>

        <div></div>

        {/* Image 1 */}
        <div className='about-img-container right-about-img'>
          <img className='about-img ' 
            src={sourcing_imgSrc} 
            srcSet= {sourcing_srcSet}
            sizes="(max-width: 400px) 400px, (max-width: 768px) 800px, 1200px"
            alt="founder" />
        </div>

        </div>

      <div className="about-content-grid about-content-grid-alt">

        {/* Paragraph 2 */}
        <article className='about-text left-side-text'>
          <section className='medium-about'>

            <p>
              Craftsmanship is as itegral to a Neoteak Product as is the Quality.
            </p>
            <p>
              Honed Crafstmen carve out the designs and products while keeping up with the ever changing demands of the industry. Hours spent on the workshop floors ensure our catalogue offers something for everyone.
            </p>
            
          </section>
        </article>

        {/* Heading 2 */}
        <article className="about-text right-side-text" >
          <section className="big-about">

            <h1>Crafstmanship</h1>
          </section>
        </article>

        {/* Image 2 */}
        <div className='about-img-container left-about-img'>
          <img className='about-img' 
            src={crafstmanship_imgSrc}
            srcSet={crafstmanship_srcSet} 
            sizes="(max-width: 400px) 400px, (max-width: 768px) 800px, 1200px"
            alt="" />
        </div>
      </div>

      
      <div className="about-content-grid"
        style={{marginBottom:"5vh"}}
      >

        {/* Heading 3 */}
        <article className="about-text left-side-text" >
          <section className="big-about">

            <h1>The Final Step</h1>
          </section>
        </article>

        {/* Paragraph 3 */}
        <article className='about-text right-side-text'>
          <section className='medium-about'>

            <p>
            The final step is for you to pick and choose from our vast catalouge of products.
            </p>
            <p>
            At Neoteak, we emphasise equally on the final product and your experience. We value your time and our service stirves to ensure you get the perfect furniture for your next renovation project.
            </p>
            
          </section>
        </article>

        <div></div>

        {/* Image 3 */}
        <div className='about-img-container right-about-img'>
          <img className='about-img' 
            src={finalStep_imgSrc} 
            srcSet={finalStep_srcSet}
            alt="final-step" />
        </div>

        {/* Heading 4 */}
        <article className="about-text left-side-text" >
          <section className="big-about">

            <h1>Our Vision</h1>
          </section>
        </article>

        {/* Empty Div */}
        <div></div>
        <div></div>

        {/* Paragraph 4 */}
        {/* <article className='about-text left-side-text'
          style={{paddingTop:"0"}}
        >
          <section className='medium-about'>
            <p>
            Neoteak aims to deliver innovative and cost-friendly furniture solutions while minimizing negative impact on the enviornment. Our values are backed by our long-standing commitment to quality and reliability. 
            </p>
            <br />
            <p>
            We want to leave our mark on your decor, not on the enviornment or your wallet.
            </p>            
          </section>
        </article> */}

        {/* Paragraph 5 */}
        {/* <article className="about-text right-side-text">
          <section className="medium-about">
            <p>
              Neoteak India's commitment to quality extends beyond its products and services. The company is committed to ensuring that its operations are sustainable and environmentally friendly. The company's manufacturing processes are designed to minimize waste and reduce its carbon footprint.
            </p>
          </section>
        </article> */}

        {/* Empty Div */}
        <div></div>

        {/* Paragraph 6 */}
        <article className="about-text left-side-text">
          <section className="medium-about">
            <p>
              <q className='quotes-text'>
              Neoteak aims to deliver innovative and cost-friendly furniture solutions while minimizing negative impact on the enviornment. Our values are backed by our long-standing commitment to quality and reliability. 
              <br />
              {/* </q>
            </p>
            <p>
              <q className='quotes-text'> */}
              We want to leave our mark on your decor, not on the enviornment or your wallet.
              </q>
            </p>
            <br />
            <p>
              -Sandeep Jain, Founder
            </p>
          </section>
        </article>

        {/* Image 3 */}
        <div>
          <img className='about-img-right'
            src="https://ik.imagekit.io/qljqw3tvn/tr:w-0800/NeoTeak/Founder.jpg" 
            alt="" />
        </div>

      </div>
    
    </motion.div>

    <div className="content-container landing-container alt-container" 
        style={{backgroundColor:`transparent`}} 
        ref={contactRef}
        >
      <Contact elemDiff={contact_diff} screenHeightConst={(screenHeight/20+1)} />
    </div>

    <Footer />
    </>
    }
    </>
  )
}

export default AboutUs