import { useState ,useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { motion } from 'framer-motion'
import Contact from '../../components/Contact/Contact';
import Accordian from '../../components/Accordian/Accordian';

import "./FAQ.css"

function KnowYourPly() {
  const params = useParams()

  const [bannerRenderFlag, setBannerRenderFlag] = useState(false)

  useEffect(()=>{
    setBannerRenderFlag(true)
    
    setTimeout(()=>{
      setBannerRenderFlag(false)
    },250)
  },[params.kwpCategory])

  return (
    <motion.div className="content-container landing-container"
      initial={{opacity:0, y:"50px"}}
      animate={{opacity:1, y:"0px"}}
      transition={{duration:0.5}} 
    >
      <div className="about-content-grid">
        <div className="content-img center-full-img" 
          style={{gridColumn:`1/ span 2`,borderRadius:`25px 25px 0 0`,height:`80vh`}}
          
        >
          
          <motion.img
            style={{borderRadius:`25px 25px 0 0`}} 
            initial={{filter:`brightness(0.8) grayscale(100%)`}}
            animate={bannerRenderFlag?{filter:`brightness(50%) grayscale(100%)`}:{filter:`brightness(80%) grayscale(100%)`}}
            transition={{duration:0.4}}
            src="https://ik.imagekit.io/qljqw3tvn/NeoTeak/pexels-dominika-roseclay-1239420__1__AR0VYDKuQ.jpg?updatedAt=1684930485745" alt="bg" />
          
          <article className='img-text'>
            <section className='big-image-text'>

              <p>
                At Neoteak we understand how hard and confusing it can be to choose the perfect wood for your dream project.
              </p>
              <br />
              
            </section>
          </article>
        </div>
        
      </div>

      <article className='faq-text right-side-text'>

          <section className='big-faq'>

            <p> 
            We just might have the right guide to help you navigate this canopy.
            </p>
              <br />

            
          </section>
        </article>

      
      <Accordian />

      <Contact textFlag={false} whatsappFlag={false} text="Still have questions?"/>

    </motion.div>
  )
}

export default KnowYourPly