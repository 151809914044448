import { useState } from 'react'
import { AnimatePresence, motion } from "framer-motion"
import emailjs from "emailjs-com"
import "./Form.css"

function Form() {
    const email_Key = process.env.REACT_APP_EMAIL_KEY
    const service_ID = process.env.REACT_APP_EMAIL_SERVICE_ID
    const template_ID = process.env.REACT_APP_EMAIL_TEMPLATE_ID
    // const template_ID_2 = process.env.REACT_APP_EMAIL_TEMPLATE_ID_2

    const [errorPopup, setErrorPopup] = useState(false)
    const [successPopup, setSuccessPopup] = useState(false)

    const [formData, setFormData] = useState({
        user_first_name:'',
        user_last_name:'',
        user_email:'',
        user_inquiry:'',
        phone:'',
    })

    const {user_first_name, user_last_name, user_email,user_inquiry, phone} = formData
  
    /////////////////////////////////////////////////
    //////// Functions /////////////////////////////
    ////////////////////////////////////////////////

    const onChange = (e) => {
        setFormData((prevState)=>({
            ...prevState, 
            [e.target.name] : e.target.value,
        }))
        // console.log(`Entered onChange`)
    }

    const handleNumField = (e) => {
        const value = e.target.value.replace(/\D/g, "");
        setFormData((prevState)=>({...prevState, phone:value}));
      };

    const onSubmit = (e) => {
        e.preventDefault()
        
        if(user_first_name==='' || user_last_name==='' || user_email==='' || user_inquiry===''
        || phone==='' ){
            // console.log(`Empty fields`)
            setErrorPopup(true)
            setTimeout(()=>{
                setErrorPopup(false)
              },2000)
        }else{

            const userData = {
                user_first_name, user_last_name, user_email,user_inquiry, phone
            }
            console.log(`userData: ${JSON.stringify(userData,null,4)}`)

            // setSuccessPopup(true)
            //     setTimeout(()=>{
            //         setSuccessPopup(false)
            //     },2000)
            
            emailjs.sendForm(service_ID,
                template_ID, 
                e.target,
                email_Key)
                .then(res=>{
                    // console.log(res)
                        setSuccessPopup(true)
                        setTimeout(()=>{
                            setSuccessPopup(false)
                        },4000)
                }).catch(err=> console.log(err))
            // emailjs.sendForm(service_ID,
            //     template_ID, 
            //     e.target,
            //     email_Key)
            //     .then(res=>{
            //         console.log(res)
            //             setSuccessPopup(true)
            //             setTimeout(()=>{
            //                 setSuccessPopup(false)
            //             },4000)
            //     }).catch(err=> console.log(err))
        }
    }

  return (
    <>
    <div className='form-container'>
        <form onSubmit={onSubmit}>
            
            <div className="form-grid">
                <div className="form-group">
                    {/* <label>Name</label> */}
                    <input type = "text" 
                        className="form-control" 
                        name = 'user_first_name'
                        id = 'user_first_name'
                        value = {user_first_name} 
                        placeholder= 'First Name'
                        onChange = {onChange}
                        autoComplete="off"
                        // required
                        />
                </div>

                <div className="form-group">
                    {/* <label>Name</label> */}
                    <input type = "text" 
                        className="form-control" 
                        name = 'user_last_name'
                        id = 'user_last_name'
                        value = {user_last_name} 
                        placeholder= 'Last Name'
                        onChange = {onChange}
                        autoComplete="off"
                        // required
                    />
                </div>


                <div className="form-group" style={{gridColumn: `1 / span 2`}}>
                    {/* <label>E-mail</label> */}
                    <input type="email" 
                        className="form-control" 
                        name='user_email'
                        id='user_email' 
                        value={user_email} 
                        placeholder='E-mail'
                        onChange={onChange}
                        autoComplete="off"
                        // required
                    />
                </div>

                <div className="form-group form-group-textarea">
                    {/* <label>Tell us more</label> */}
                    <textarea type="text" rows="2"
                        className="form-control form-control-textarea" 
                        name='user_inquiry'
                        id='user_inquiry' 
                        value={user_inquiry} 
                        placeholder='Inquiry'
                        onChange={onChange}
                        autoComplete="off"
                        // required
                    />
                </div>
                <div className="form-group">
                    {/* <label>Phone</label> */}
                    <input type="text" 
                        className="form-control" 
                        name='phone'
                        id='phone' 
                        value={phone} 
                        placeholder='Phone number'
                        onChange={handleNumField}
                        autoComplete="off"
                        // required
                    />
                </div>

                <div className="alert-submit-box">

                    <div className="form-group">
                        <button type="submit" className="submit-btn">
                            Submit
                        </button>
                    </div>

                    <AnimatePresence>
                        {errorPopup &&
                            <motion.div className="form-group fill-space"
                            initial={{opacity:0}} 
                            animate={{opacity:1}}
                            exit={{opacity:0}}
                            transition={{duration:0.8}}
                            >
                                <div className="error-alert">
                                    Please fill all the fields
                                </div>
                            </motion.div>
                        } 
                    </AnimatePresence>

                    <AnimatePresence>
                        {successPopup && 
                         <motion.div className="form-group fill-space"
                            initial={{opacity:0}} 
                            animate={{opacity:1}}
                            exit={{opacity:0}}
                            transition={{duration:0.8}}
                            > 
                                <div className="error-alert success-alert">
                                    We will get back to you soon!
                                </div>
                        </motion.div>
                        }
                    </AnimatePresence>

                </div>
            </div>
            
            

        </form>
    </div>

    
    </>
  )
}

export default Form