// import React, { useEffect, useState } from 'react'
import {motion} from 'framer-motion'
import "./Loading.css"
import { useEffect, useState } from 'react'

function LoadingTransition() {
    const bgColour = `var(--templateContrast)`
    const bgColour1 = "#22573C"
    const bgColour2 = `var(--templateBackground)`

    const [logoFlag, setLogoFlag] = useState(false)

  useEffect(()=>{
    setTimeout(()=>{
      setLogoFlag(true)
    },1800)
  },[])

  return (
    <>
    <div className="overlay-container panel1"
        // initial={{x:"-100vw"}}
        // animate={{x:0}}
        // transition={{duration:0.6}}
        // style={{backgroundColor:bgColour}} 
    >
    </div>

    <div className="overlay-container panel1-text"
      // initial={{opacity:1, x:`-5vw`}}
      // animate={{opacity:1, x:0}}
      // transition={{duration:0.6}}
      // style={{backgroundColor:`transparent`}}
    >
        <img className='loading-logo-img' 
          src="https://ik.imagekit.io/qljqw3tvn/NeoTeak/Neoteak_Logo_Line_urwFMVvPc7.png" alt="" />

        <h1 style={{color:bgColour2, fontFamily: "Sabon" }}
        >
          Neoteak
        </h1>

        <p style={{color:bgColour2}}
        >
          Ply of the New World
        </p>
    </div>
        
    <div className={`overlay-container panel2`}
        // initial={{y:"-100vh"}}
        // animate={{y:0}}
        // transition={{delay:0.6,duration:0.8}} 
        // style={{backgroundColor:bgColour2}} 
        >
        
    </div>

    <div className="overlay-container panel2-text"
      // initial={{opacity:0,}}
      // animate={{opacity:1,}}
      // // exit={{opacity:0}}
      // transition={{delay:0.8,duration:0.5}}
      // style={{backgroundColor:`transparent`}}
      >
        <img className={`loading-logo-img  ${logoFlag?"overlay-fade-out":""}`}
          src="https://ik.imagekit.io/qljqw3tvn/NeoTeak/Neoteak_Logo_Colour_k1-WfXXH6.png?updatedAt=1686749706183" alt="" />
        <h1 className={`${logoFlag?"overlay-fade-out":""}`} 
          style={{color:bgColour1, fontFamily: "Sabon"}}
        >
          Neoteak
        </h1>
        <p className={` ${logoFlag?"overlay-fade-out":""}`}
          style={{color:bgColour}} 
        >
          मजबूती ऐसी जो चले सालों साल
        </p>
    </div>
    </>
  )
}

export default LoadingTransition