// import { useState } from 'react'
import "./Accordian.css"
import AccorItem from './AccorItem'
import KWPList from '../Lists/KWPList'
import { useParams } from 'react-router-dom'

function Accordian() {
    const params = useParams();
    
  return (
    <div className='accordian-container'>
        {KWPList.filter(elem=>elem.category===params.kwpCategory).map((item,index)=>{
            return(
                <AccorItem key={index} question={item.question} answer={item.answer} />

            )
        })}
    </div>
  )
}

export default Accordian