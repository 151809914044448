import './App.css';
import {useState} from 'react'
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import { AnimatePresence } from 'framer-motion';
// import { useMediaQuery } from 'react-responsive'
import Navbar from './components/Navbar/Navbar';

import LandingPage from './pages/LandingPage/LandingPage';
import AboutUs from './pages/AboutUs/AboutUs';
import Products from './pages/Products/Products';
import FAQ from './pages/FAQ/FAQ';
import ProductSlider from './pages/Products/ProductSlider';
import Partners from './pages/Partners/Partners';
import KnowYourPly from './pages/FAQ/KnowYourPly';
import NotFound from './pages/NotFound/NotFound';

function App() {
  const [prodRenderFlag, setProdRenderFlag]=useState(false)

  return (
    <>
    {/* <AnimateSharedLayout type='crossfade'> */}
    <AnimatePresence>
    
    <Router>

      <div className='container'>
        <Navbar />

        <Routes>
          <Route path='/' element={<LandingPage />} /> 
          
          <Route path='about-us' element={<AboutUs />} />
          
          <Route path='know-your-ply' element={<FAQ />} >
            {/* <Route path='*' element={<NotFound/>} /> */}
            <Route path=':kwpCategory' element={<KnowYourPly />} />
          </Route>

          <Route path='/products/*' element={<Products passRenderFlag={setProdRenderFlag} />}>
            <Route path=':prodCategory' element={<ProductSlider renderFlag={prodRenderFlag}/>} />
          </Route>
          <Route path='channel-partners' element={<Partners />} />

          <Route path='*' element={<NotFound/>} />

        </Routes>
      </div>
    
    </Router>
    
    </AnimatePresence>
    {/* </AnimateSharedLayout> */}
    </>
  );
}

export default App;
