const ProdCategoryList = [{
    categoryName:`Plywood`,
    categorySlug:`plywood`,
    categoryDesc:`Being an extremely versatile product, plywood is used for a wide range of application, spanning from dinning, wardrobe, bed, table to wall panelling and shelves. We at Neoteak offer only superior quality plywood with maximum precision, uniform thickness and high density.`,
},{
    categoryName:`Blockboard`,
    categorySlug:`blockboard`,
    categoryDesc:`Blockboard is majorly used for a wide range of application, spanning from wardrobe shutters, table top doors and shelves. We at Neoteak offer only superior quality board with best quality face, 100% pine filling, triple core ply, uniform thickness and high density.`,
},{
    categoryName:`Laminates`,
    categorySlug:`laminates`,
    categoryDesc:`We, at Neoteak, are committed to designing innovative decorative finishes that enhance spaces in which they are found. Whether striking high gloss, bright solid colours, metals or realistic woods, Veneer finish each design is hand picked and papers are imported from all over the world.`,
},{
    categoryName:`Veneer`,
    categorySlug:`veneer`,
    categoryDesc:`Neoteak veneers are made of exotic species specially handpicked from across the globe such as America, Australia, Brazil, Europe, and Africa adding the beauty of wood to your furniture. Our veneers are made with superior quality base plywood and full thickness of front face.a`,
},{
    categoryName:`Flush Door`,
    categorySlug:`flush-door`,
    categoryDesc:`Neoteak flush doors comes with strength, dimensional stability and high impact resistance to offer security, protection and privacy. Flush doors are tailor-made to suit the varying needs of the consumers, be it for residential or commercial purpose. These doors have provision for lock on both sides and the frame is made with 100% imported pine and suitable for all sorts of climatic constructions.`,
},]

export default ProdCategoryList