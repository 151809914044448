const KWPList=[
//Plywood
{
    category:`plywood`,
    question:`What is plywood & how is it made?`,
    answer:[`Plywood is a type of engineered wood panel that is made by bonding together thin layers or plies of wood veneer. These veneer plies are typically obtained by peeling or slicing logs. Each ply is placed with its grain direction perpendicular to the adjacent plies, which gives plywood its strength and stability.`,
            `Overall, plywood is a versatile and widely used building material that provides strength, durability, and versatility for a variety of applications.`],
},{
    category:`plywood`,
    question:`What are different grades of Plywood?`,
    answer:[`MR, BWR (303), and BWP(710) are grades commonly used to indicate the water resistance or moisture resistance of plywood.`,
            `MR Grade: MR stands for "Moisture Resistant" or "Moisture Resistant". MR grade plywood is suitable for interior applications where it will not be exposed to excessive moisture or water. It is bonded with a phenol-formaldehyde resin that provides moderate resistance to moisture but is not completely waterproof. MR grade plywood is commonly used for furniture, cabinets, and other indoor projects.`,
            `BWR Grade: BWR stands for "Boiling Water Resistant." BWR grade plywood is designed for applications where the plywood may be exposed to occasional or intermittent contact with water. It is bonded with a phenol-formaldehyde resin that offers better water resistance compared to MR grade plywood. BWR grade plywood is commonly used for kitchen cabinets, bathroom furniture, and other areas where moderate moisture exposure is expected.`,
            `BWP Grade: BWP stands for "Boiling Water Proof" or "Boiling Water Proof Exterior Grade." BWP grade plywood is specifically engineered for outdoor or exterior applications where the plywood will be exposed to significant moisture or water. It is manufactured with a phenol-formaldehyde resin that provides excellent water resistance and durability. BWP grade plywood is commonly used for outdoor furniture, exterior cladding, boat building, and other applications where high moisture resistance is required.`
        ],
},{
    category:`plywood`,
    question:`What are different faces used in plywood?`,
    answer:[`Okoume Face: Okoume is a tropical hardwood species that is commonly used as a face veneer in plywood. It is known for its lightweight nature, attractive reddish-brown color, and uniform grain pattern. Okoume face veneers are typically used in high-quality plywood, particularly in marine-grade plywood, where water resistance and durability are important. It isalso used in furniture, cabinets, and decorative applications where a smooth and visually appealing surface is desired.`,
            `Gurjan Face: Gurjan is another tropical hardwood species that is frequently used as a face veneer in plywood. It is known for its strength, stability, and resistance to moisture and rot. Gurjan face veneers are commonly found in high-quality plywood, including marine-grade and exterior-grade plywood. They provide excellent durability and are suitable for applications where the plywood will be exposed to moisture or outdoor conditions.`,
            `Recon Face: "Recon face" is a term used to refer to the reconstructed face veneer used in some plywood It is also known as reconstituted or engineered face veneer.Recon face veneer is created by taking thin wood veneer strips or flakes and reassembling them into a new sheet. Reconstructed face veneer can have a consistent appearance, with an engineered pattern or grain, depending on the manufacturing process.Recon face veneer is often used in plywood as a cost-effective alternative to using natural wood face veneers. It provides a more uniform appearance and can mimic the look of certain wood species or grain patterns. Recon face veneer is commonly used in lower-grade or utility-grade plywood, where appearance is less critical and cost efficiency is prioritized`
        ],
},{
    category:`plywood`,
    question:`What is an average pricing of plywood?`,
    answer:[`The pricing of plywood can vary significantly based on several factors such as the type of plywood, grade, size, thickness, brand, and location. Additionally, market conditions and availability can also impact the price.`,
            `However, as a general guideline, here are some approximate price ranges for common types of plywood:`,
            `Softwood Plywood (Poplar Plywood): The cost of softwood plywood (Poplar Plywood) typically ranges from ₹25 to₹60 per square feet, depending on the size, thickness, and grade.`,
            `Softwood & Hardwood Plywood (Alternate Plywood): Softwood & Hardwood Plywood (Alternate Plywood) is usually more expensive than softwood plywood due to the cost of the hardwood veneers used. Prices can range from ₹45 to ₹85 per square feet, depending on the species of hardwood, thickness, and grade.`,
            `Hardwood Plywood: Hardwood plywood is usually more expensive than Alternate Plywood due to the cost of the hardwood veneers used. Prices can range from ₹45 to ₹95 per square feet, depending on the species of hardwood, thickness, and grade.`,
            `Marine-Grade Plywood: Marine-grade plywood, which is specifically designed for moisture resistance, tends to be more expensive. Prices can range from ₹70 to ₹150 per square feet, depending on the size, thickness, and brand.`,
            `Specialty Plywood: Specialty plywood, such as fire-resistant or impact-resistant plywood, can have higher prices due to additional manufacturing processes and materials. Prices for specialty plywood can vary widely depending on the specific features and requirements.`
        ],
},{
    category:`plywood`,
    question:`What is the difference between MDF & Plywood?`,
    answer:[`MDF (Medium-Density Fiberboard) and Plywood are both engineered wood products, but they have some notable differences in terms of composition, properties, and uses.`,
            `Plywood: Plywood is known for its strength and durability. The cross-laminated construction of plywood gives it excellent strength and resistance to warping and twisting. It has good load-bearing capabilities and is suitable for structural applications. Plywood is generally more expensive than MDF due to the manufacturing process and the use of wood veneers.`,
            `MDF: MDF is not as strong as plywood and has lower structural strength. It is more prone to sagging and can be less durable when exposed to moisture. MDF is typically used for applications where strength and load-bearing capacity are not critical. MDF is often less expensive than plywood, making it a cost-effective option for various applications. In summary, plywood offers better structural strength, moisture resistance, and durability, making it suitable for applications requiring load-bearing capabilities. MDF provides a smooth and uniform surface and is more cost-effective but is not as strong or moisture-resistant as plywood. The choice between the two depends on the specific requirements of the project, such as strength, appearance, budget, and moisture exposure.`
        ]
},{
    category:`plywood`,
    question:`What grade of plywood is suitable for kitchen?`,
    answer:[`When selecting plywood for kitchen cabinets or other kitchen applications, it is recommended to choose a grade that offers good durability, moisture resistance, and a suitable surface finish.`,
            `BWR/BWP Grade (Boiling Water Resistant/Boiling Water Proof): BWR and BWP grade plywood are designed to resist moisture and water exposure. These grades offer better moisture resistance than MR (Moisture Resistant) grade plywood. BWR/BWP grade plywood is suitable for kitchen cabinets, shelves, and other kitchen furniture that may come into contact with water or experience occasional humidity.`,
        ]
},{
    category:`plywood`,
    question:`What is better option for kitchen cabinets? Marine grade plywood or HDHMR?`,
    answer:[`Both marine grade plywood and HDHMR (High-Density High Moisture Resistance) are suitable options for kitchen cabinets, but they have different characteristics and considerations.`,
            `Marine grade/ BWP (Is 710): Marine grade plywood is known for its strength, stability, and resistance to moisture and water. It is designed to withstand harsh marine environments and offers excellent durability. It provides a high level of moisture resistance, making it suitable for kitchen applications where water exposure or high humidity is a concern. It is generally more expensive than other plywood options due to its enhanced moisture resistance and durability.`,
            `HDHMR (High-Density High Moisture Resistance): HDHMR is a composite panel made of wood fibers, resins, and additives. It is engineered to provide strength and durability similar to marine grade plywood. It designed to offer high moisture resistance, making them suitable for kitchen applications. They have a moisture-resistant surface and core, which helps prevent swelling or warping when exposed to moisture. It is generally less expensive than marine grade plywood, making it a cost-effective option for kitchen cabinets.`,
            `In summary, marine grade plywood offers better structural strength, moisture resistance, and durability, making it suitable for Kitchen cabinet, HDHMR provides a smooth and uniform surface and is more cost-effective but is not as strong as plywood. The choice between the two depends on the specific requirements of the project, such as strength, appearance, and budget.`
        ]
},{
    category:`plywood`,
    question:`How is plywood different from solid wood?`,
    answer:[`Plywood and solid wood are two distinct types of materials used in woodworking`,
            `Plywood: Plywood is an engineered wood product made by bonding together multiple layers or plies of wood veneer. Each layer is typically oriented with its grain direction perpendicular to the adjacent layers. This construction method gives plywood its strength, stability, and resistance to warping. Plywood is designed to provide enhanced strength and stability compared to solid wood. The cross-laminated construction of plywood distributes forces more evenly, making it less prone to warping, twisting, or splitting. It has consistent strength across the entire sheet and can be manufactured to have specific strength. Plywood is manufactured in large sheets and is readily available in various standard sizes and thicknesses. It can be easily cut into smaller pieces or customized to fit specific requirements.`,
            `Solid Wood: Solid wood, as the name suggests, is made from a single piece of wood. It is obtained directly from trees and retains the natural grain pattern and characteristics of the wood species. Solid wood is naturally strong but can be susceptible to warping and movement due to changes in temperature and humidity. It may require additional joinery techniques or special construction methods to minimize these issues. Solid wood is available in various sizes and dimensions, but it is limited by the size of the tree from which it is obtained. Larger pieces of solid wood may be more challenging to find and may come at a higher cost.`
        ]
},{
    category:`plywood`,
    question:`What are the standard sizes and thickness of plywood sheets?`,
    answer:[`Plywood sheets are typically available in standard sizes and thicknesses, Here are the commonly used standard sizes and thicknesses of plywood sheets`,
            `Standard Sizes:`,
            `- 4 feet x 8 feet (1220 mm x 2440 mm): This is the most common and widely available size of plywood sheets.`,
            `- 4 feet x 7 feet (1220 mm x 2134 mm)`,
            `- 4 feet x 6 feet (1220 mm x 1830 mm)`,
            `- 3 feet x 8 feet (915 mm x 2440 mm)`,
            `- 3 feet x 7 feet (915 mm x 2134 mm)`,
            `- 3 feet x 6 feet (915 mm x 1830 mm)`,
            `Standard Thicknesses`,
            `- 4mm (0.15 Inch)`,
            `- 6mm (0.23 Inch)`,
            `- 9mm (0.35 Inch)`,
            `- 12mm (0.47 Inch)`,
            `- 18mm (0.70 Inch)`,
            `- 25mm (1 Inch)`
        ]
},{
    category:`plywood`,
    question:`Is WPC as good as of plywood?`,
    answer:[`WPC (Wood Plastic Composite) and plywood are two different materials with distinct characteristics and uses. Comparing their suitability depends on the specific application and requirements.`,
            `WPC is a composite material made of a combination of wood fibres or flour and thermoplastics, typically polyethylene or polypropylene. It may also contain additives and stabilizers. WPC has good moisture resistance and is less prone to warping, rotting, or swelling compared to natural wood. However, its moisture resistance may not be as high as certain grades of plywood, such as marine-grade or exterior-grade plywood. However WPC may not have the same structural strength as materials like plywood or solid wood. It may have limitations in supporting heavy loads or being used in applications where strength and durability are crucial.`,
            `In summary, WPC and plywood have their own advantages and considerations. WPC offers good moisture resistance, durability, and a consistent appearance, while plywood provides strength, load-bearing capacity, and a natural wood grain appearance. The choice between WPC and plywood depends on factors such as the specific application, desired aesthetics, moisture resistance requirements, and budget`
        ]
},{
    category:`plywood`,
    question:`Does the surface of plywood affect the finishing of furniture?`,
    answer:[`Yes, the surface of plywood can affect the finishing of furniture. The surface quality and characteristics of plywood can impact how well it accepts various finishes, such as paints, stains, varnishes, or laminates. The smoothness of the plywood surface can impact the final finish. A smooth surface is generally desirable for achieving a high-quality finish. If the plywood surface is rough or uneven, it may require additional sanding or preparation to achieve a smooth finish. Plywood surfaces can have imperfections such as knots, voids, or patches, especially in lower grade or lower-cost plywood. These imperfections may affect the final appearance and may require additional attention during the finishing process.`
        ]
},{
    category:`plywood`,
    question:`Is Particle Board furniture as durable as Plywood furniture?`,
    answer:[`Particle board furniture is generally less durable compared to plywood furniture. Particle board is made from wood particles or fibres that are bonded together with adhesive and compressed under high pressure. Plywood, on the other hand, is made from layers of wood veneers that are glued together with each layer's grain direction perpendicular to the adjacent layers. Plywood's layered construction provides enhanced strength and stability compared to particle board. Plywood is known for its strength and load-bearing capacity. It can withstand heavier loads and is less prone to sagging or breaking under stress. Particle board, while suitable for lighter applications, may not have the same level of strength and may be more susceptible to damage or failure when subjected to heavy loads or excessive stress. Plywood is generally easier to repair or modify compared to particle board. It can be cut, drilled, or reshaped more effectively, whereas particle board may not hold screws or hardware as securely and may require additional reinforcement or special techniques for repairs or modifications.`,
            `Ultimately, when choosing between particle board and plywood furniture, it's important to consider the specific application, expected usage, and budget. Plywood is generally recommended for applications that require higher strength, durability, and resistance to moisture, while particle board may be suitable for lighter-duty or budget-friendly projects.`,
        ]
},{
    category:`plywood`,
    question:`Can plywood be painted or stained?`,
    answer:[`Yes, plywood can be painted or stained. In fact, plywood is a popular choice for furniture, cabinets, and other woodworking projects precisely because it provides a smooth surface that can be finished in various ways. Remember to always follow the specific instructions provided by the paint or stain manufacturer for best results. Additionally, testing the paint or stain on a small inconspicuous area of the plywood or a scrap piece is recommended to ensure the desired colour and finish before proceeding with the entire project.`
        ]
},{
    category:`plywood`,
    question:`What is the lifespan of plywood?`,
    answer:[`The lifespan of plywood can vary depending on several factors such as the quality of the plywood, its intended use, environmental conditions, and maintenance. Generally, plywood is a durable material and can have a relatively long lifespan.`,
            `However the quality of the plywood plays a significant role in determining its lifespan. Higher-quality plywood, made from well-bonded layers of high-grade veneers, tends to be more durable and long-lasting compared to lower-quality plywood. The environment in which the plywood is installed or used can affect its lifespan too. Exposure to moisture, extreme temperature fluctuations, direct sunlight, or other harsh conditions can accelerate deterioration and decrease the lifespan of plywood. Using the appropriate grade of plywood for specific environmental conditions, such as marine-grade plywood for wet or humid areas, can help improve its durability.`,
        ]
},{
    category:`plywood`,
    question:`What are the common issues or problems with plywood?`,
    answer:[`While plywood is a widely used and versatile material, it can have some common issues or problems that may arise in certain circumstances. Plywood is susceptible to damage from prolonged exposure to moisture. When exposed to excessive moisture or humidity, plywood can swell, warp, delaminate, or develop mold and rot. It is important to use the appropriate grade of plywood, such as marine-grade or exterior-grade plywood, in areas where moisture resistance is required, and to protect plywood from direct water contact. Delamination occurs when the layers of plywood separate or peel apart. This can be caused by moisture damage, exposure to extreme temperature changes, or inadequate adhesive bonding during the manufacturing process. Delamination weakens the plywood's structural integrity and can result in a loss of strength. Borer and termite infestation can indeed be a problem with plywood, especially if the plywood is not properly treated or protected Plywood, being made of wood, can be susceptible to attack by borers and termites. These pests are attracted to the cellulose present in wood, and if given the opportunity, they can cause significant damage to plywood over time. o prevent borer and termite infestation in plywood, it is essential to use plywood that has been properly treated with appropriate preservatives or chemicals. Treated plywood is specifically designed to deter or repel pests, providing a higher level of resistance to borer and termite attacks.`,
        ]
},

//Blockboard
{
    category:`blockboard`,
    question:`What is a block board?`,
    answer:[`A blockboard is a type of engineered wood panel composed of a core made of wooden blocks or strips placed between two outer layers or faces, typically made of plywood. It is known for its strength, stability, and resistance to warping.`,
        ]
},
{
    category:`blockboard`,
    question:`What are the uses of blockboards?`,
    answer:[`Block boards have a wide range of applications in the construction and furniture industries. They are commonly used for making doors, tabletops, shelves, cabinets, partitions, and other furniture pieces where strength and stability are important.`,
        ]
},
{
    category:`blockboard`,
    question:`How is blockboard different from plywood?`,
    answer:[`Blockboards and plywood are both engineered wood products, but they have some differences. While plywood consists of several layers of thin wood veneers glued together with the grain direction alternating per layer, blockboards have a core made of solid wood blocks or strips. Blockboards are typically heavier and more rigid than plywood and are often used for applications where added strength is required.`,
        ]
},
{
    category:`blockboard`,
    question:`What are the advantages of using blockboards?`,
    answer:[`Blockboards offer several advantages`,
            `-High strength and stability`,
            `-Resistance to warping and cracking`,
            `-Ability to hold screws and other fasteners securely`,
            `-Easy to work with using standard woodworking tools`,
            `-Availability in large sizes for seamless furniture construction`
        ]
},
{
    category:`blockboard`,
    question:`How do I choose the right blockboard for my project?`,
    answer:[`Consider the following factors when selecting blockboards`,
            `Core material: Different types of wood can be used for the core, each with its own characteristics. Choose a core material that suits your specific project requirements.`,
            `Thickness: Blockboards come in various thicknesses. Consider the load-bearing capacity and the specific application to determine the appropriate thickness.`,
            `Grade: Blockboards are available in different grades based on the quality of the faces and core. Higher grades offer better aesthetics and structural performance.`
        ]
},


{
    category:`blockboard`,
    question:`Can blockboards be painted or finished?`,
    answer:[`Yes, blockboards can be painted or finished. The outer faces of blockboards are typically made of plywood, which provides a smooth surface for paint, stains, or varnishes. Ensure proper surface preparation and follow appropriate finishing techniques for best results.`,
        ]
},
{
    category:`blockboard`,
    question:`Can blockboards be used in outdoor applications?`,
    answer:[`Blockboards are not naturally resistant to moisture or weathering, so they are generally not recommended for direct outdoor exposure. However, they can be used in protected outdoor applications where they are shielded from rain and excessive moisture.`,
        ]
},

//Laminate
{
    category:`laminates`,
    question:`What are laminate sheets?`,
    answer:[`Laminate sheets are synthetic materials that consist of multiple layers. They typically have a decorative layer on top, which can mimic the appearance of wood, stone, or other materials, and a durable protective layer on the bottom. The layers are fused together under heat and pressure to create a sheet that is used as a surfacing material for various applications.`,
        ]
},
{
    category:`laminates`,
    question:`What is the difference between laminate sheets and veneer sheets?`,
    answer:[`Laminate sheets are synthetic materials made from layers of resin-impregnated paper or fabric, while veneer sheets are thin slices of real wood. Laminate sheets are manufactured to mimic the appearance of various materials, including wood, while veneer sheets provide the genuine look of solid wood.`,
        ]
},
{
    category:`laminates`,
    question:`What are the advantages of using laminate sheets?`,
    answer:[`Laminate sheets offer several advantages. They are highly durable, resistant to scratches, stains, and impact. They are also easy to clean and maintain. Laminate sheets come in a wide range of colors, patterns, and textures, providing versatility in design options. They are generally more affordable than natural materials like wood or stones.`,
        ]
},
{
    category:`laminates`,
    question:`What is the standard thickness of laminate sheets?`,
    answer:[`The standard thickness of laminate sheets can vary depending on the specific product and application. However, the most common thickness for laminate sheets used in residential and commercial applications is typically between 0.8mm to 1.5mm.`,
        ]
},
{
    category:`laminates`,
    question:`Where are laminate sheets used?`,
    answer:[`Laminate sheets are used in a variety of applications. They are commonly used for kitchen cabinets, furniture surfaces, wall panels and decorative elements. Laminate sheets are also used in commercial settings such as offices, retail stores, and hospitality spaces.`,
        ]
},

{
    category:`laminates`,
    question:`Can laminate sheets be repaired if damaged? `,
    answer:[`Minor damage to laminate sheets can sometimes be repaired. However, deep cuts or significant damage may require replacing the affected section or the entire sheet.`,
        ]
},
{
    category:`laminates`,
    question:`Can laminate sheets be used in wet areas like bathrooms or kitchens?`,
    answer:[`Laminate sheets are moisture-resistant but not entirely waterproof. While they can handle occasional splashes and normal humidity, prolonged exposure to standing water or excessive moisture can cause damage. In wet areas, it's important to ensure proper sealing and use appropriate sealants in joints and edges to prevent water penetration.`,
        ]
},
{
    category:`laminates`,
    question:`What are disadvantages of laminate sheet?`,
    answer:[`Laminate sheets, despite their ability to mimic the appearance of natural materials like wood or stone, do not have the same authentic look and feel as the real thing. While laminate sheets are moisture-resistant, they are not completely waterproof. Prolonged exposure to standing water or excessive moisture can cause swelling, warping, or delamination of the laminate surface. Deep cuts or significant damage to laminate sheets may be challenging to fix. In such cases, replacing the affected section or the entire sheet may be necessary, which can be more involved and costly compared to repairing natural materials like Veneer. Laminate sheets are generally less heat-resistant compared to natural materials like stone, solid wood or veneer. Prolonged exposure to high heat can cause discoloration, bubbling, or warping of the laminate surface. When installing larger surfaces with laminate sheets, such as countertops or large furniture panels, multiple sheets may be required. This can result in visible seams between the sheets, which may be more noticeable compared to natural materials. While laminate sheets are durable and can last for many years with proper care, they generally have a shorter lifespan compared to natural materials. Over time, the protective layer on the laminate surface may wear down or become scratched, potentially affecting its appearance and performance.`,
        ]
},

//Veneer
{
    category:`veneer`,
    question:`What are veneer sheets?`,
    answer:[`Veneer sheets are thin slices of wood that are typically used to cover the surface of furniture, cabinets, and other wooden objects. They are made by slicing or peeling a log into thin sheets, which are then glued onto a substrate to create a finished surface that resembles solid wood.`,
        ]
},
{
    category:`veneer`,
    question:`What types of wood are used to make veneer sheets?`,
    answer:[`Veneer sheets can be made from a wide variety of wood species. Common types of wood used for veneer include teak, oak, maple, cherry, walnut, and mahogany. Exotic woods such as santos, ebony, and rosewood are also used for specialty veneers.`,
        ]
},
{
    category:`veneer`,
    question:`What are the advantages of using veneer sheets?`,
    answer:[`Veneer sheets offer several advantages over solid wood. They are more cost-effective since they can be made from smaller, lower-grade pieces of wood. Veneer also allows for more efficient use of precious and exotic woods. Additionally, veneer sheets are less prone to warping, splitting, and shrinking compared to solid wood.`,
        ]
},
{
    category:`veneer`,
    question:`How thick are veneer sheets?`,
    answer:[`Veneer sheets are typically very thin, ranging from 0.5mm to 3.5mm in thickness. Thicker veneers, such as those used for high-quality furniture, can be up to 6mm thickness.`,
        ]
},
{
    category:`veneer`,
    question:`How are veneer sheets applied to surfaces?`,
    answer:[`Veneer sheets are usually applied to a substrate using adhesive. The substrate can be plywood, flush door, block board, medium-density fiberboard (MDF), particleboard, or another suitable material. The adhesive is applied to both the substrate and the veneer, and then the veneer is pressed onto the substrate using pressure or a veneer press.`,
        ]
},

{
    category:`veneer`,
    question:`How long do veneer sheets last?`,
    answer:[`The lifespan of veneer sheets depends on various factors such as the quality of the veneer, the application, and the level of care. When properly maintained, veneer can last for decades. However, it is important to avoid excessive moisture, direct sunlight, and harsh chemicals that could damage the veneer.`,
        ]
},
{
    category:`veneer`,
    question:`Can veneer sheets be repaired if damaged?`,
    answer:[`Depending on the extent of the damage, veneer sheets can often be repaired. Minor scratches or dents can be repaired by sanding and refinishing the affected area. Deeper damage may require patching or replacing the damaged section of the veneer.`,
        ]
},
{
    category:`veneer`,
    question:`What are disadvantages of veneer sheets?`,
    answer:[`Veneer sheets are generally thinner than solid wood, ranging from 0.5mm to 3.5mm in thickness. This makes them more susceptible to damage such as scratches, dents, and chips. Veneer sheets are sensitive to changes in moisture levels. They can expand or contract when exposed to fluctuations in humidity, which may lead to warping or buckling. Veneer sheets are generally more expensive than laminate sheets, which are synthetic materials designed to replicate the appearance of wood. The cost of veneer sheets can vary depending on the wood species used, the quality of the veneer, and the manufacturing process. If you are specifically interested in veneer sheets made from exotic wood species, their availability may be more limited compared to common wood species. Exotic wood veneers can be more expensive and may require special sourcing or ordering.`,
        ]
},

//Flush Door
{
    category:`flush-door`,
    question:`What is a flush door?`,
    answer:[`A flush door is a type of interior door that is constructed with a solid or semi-solid core encased between two flat plywood or medium-density fiberboard (MDF) faces. It is called a "flush" door because the faces are flush with the edges of the door, providing a sleek and simple appearance.`,
        ]
},{
    category:`flush-door`,
    question:`What is the standard thickness of a flush door?`,
    answer:[`The standard thickness of a flush door is typically 1.18 inches (30 mm), 1.25 inches (32 mm) and 1.37 inches (35 mm). However, variations in thickness are available to accommodate specific requirements and preferences.`,
        ]
},{
    category:`flush-door`,
    question:`Are flush doors available with different core materials?`,
    answer:[`Yes, flush doors are available with different core materials, including Pine wood batten, solid wood batten, hollow-core, particleboard, or a combination of materials. The choice of core material can impact factors such as door weight, strength and cost.`,
        ]
},{
    category:`flush-door`,
    question:`What is the suitable thickness of flush door?`,
    answer:[`The suitable thickness of a flush door depends on several factors, including the intended application, structural requirements and aesthetical appeal. However, here are some general suitable thicknesses of flush doors. For standard interior applications, such as residential homes or commercial buildings, the suitable thickness of a flush door is 1.18 inches (30 mm) and 1.37 inches (35 mm). This thickness provides adequate structural stability and sound insulation for interior use.`,
        ]
},{
    category:`flush-door`,
    question:`What are batten used in flush door?`,
    answer:[`A batten is used to provide additional structural support and stability. The batten is a narrow strip of wood that is placed vertically in the centre of the door, running from the top to the bottom. This technique is often employed in flush doors to prevent warping, twisting, or sagging over time. Flush doors, especially those with thin profiles, may be more prone to deformation due to the stresses and strains that can occur during use or changes in humidity and temperature. The batten helps to distribute these forces more evenly across the door's surface, minimizing the risk of warping or twisting.`,
        ]
},{
    category:`flush-door`,
    question:`What are the advantages of flush doors?`,
    answer:[`Flush doors offer several advantages. Flush doors are generally more cost-effective compared to other types of doors. They come in various sizes, designs, and finishes to suit different interior styles. The smooth surfaces of flush doors make them easy to clean and maintain. Flush doors are known for their strength and resistance to warping or twisting.`,
        ]
},{
    category:`flush-door`,
    question:`Can flush doors be used for external applications?`,
    answer:[`Flush doors are primarily designed for interior use. While they can be used as external doors in some cases, they may not be as suitable as specialized exterior doors that offer better weather resistance.`,
        ]
},{
    category:`flush-door`,
    question:`Are flush doors fire-rated?`,
    answer:[`Yes, fire-rated flush doors are available. These doors are specially designed and tested to provide fire resistance for a specified duration, typically ranging from 30 minutes to a few hours. Fire-rated flush doors are commonly used in commercial and public buildings to improve fire safety.`,
        ]
},{
    category:`flush-door`,
    question:`Are flush doors soundproof?`,
    answer:[`Flush doors, particularly those with solid cores, offer some level of sound insulation but are not considered as highly soundproof as specialized acoustic doors. To enhance soundproofing, additional measures such as using sealing strips or installing door sweeps can be taken.`,
        ]
},{
    category:`flush-door`,
    question:`Can flush doors be used for both residential and commercial applications?`,
    answer:[`Yes, flush doors are suitable for both residential and commercial applications. They are commonly used in homes, offices, hotels, schools, hospitals, and other buildings where a clean and simple aesthetic is desired.`,
        ]
}
]

export default KWPList