import {useState, useEffect, useRef} from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import {motion} from 'framer-motion'

import ProdCategoryList from '../../components/Lists/ProdCategoryList';
import Contact from '../../components/Contact/Contact';
import NotFound from '../NotFound/NotFound';
import LoadingTransition from '../../components/Loading/LoadingTransition';

import "./FAQ.css"
import FAQTransition from '../../components/Loading/FAQTransition';
import Footer from '../../components/Footer/Footer';

function FAQ() {
  const navigate = useNavigate();
  const params = useParams()

  const faqSlug = ProdCategoryList.filter((item)=>item.categorySlug===params.kwpCategory).length>0?ProdCategoryList.filter((item)=>item.categorySlug===params.kwpCategory)[0].categoryName:""

  const [offset, setOffset] = useState(0);

  const faqRef = useRef();
  const [faq_offset, setFaq_offset] = useState()
  const [faq_diff, setFaq_diff] = useState()

  const contactRef = useRef();
  const [contact_offset, setContact_offset] = useState()
  const [contact_diff, setContact_diff] = useState()

  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  const [loadingFlag, setLoadingFlag] = useState(false)
  const [bannerRenderFlag, setBannerRenderFlag] = useState(false)

  ////////////////////////////////////
  //////////Fucntions/////////////////
  ////////////////////////////////////

  //Get Ref Positoin relative to window
  const getPosition=()=>{
    if(faqSlug!==""){
        let faq = faqRef.current.offsetTop;
        let contact = typeof contactRef.current.offsetTop===undefined?0:contactRef.current.offsetTop;
      // console.log(`faqRef:${faqRef.current.offsetTop}`)

      setFaq_offset(faq)
      setContact_offset(contact)
      }
    }

    const handleKWPCatClick=(catPath)=>{
      if(!(params.kwpCategory===catPath)){
        setBannerRenderFlag(true)
      }
      setTimeout(()=>{
        setBannerRenderFlag(false)
        navigate(catPath)
      },100)
    }

  ////////////////////////////////////
  //////////Hooks/////////////////////
  ////////////////////////////////////

  //Get Screen Height
  useEffect(() => {
    const updateDimension = () => {
        setScreenHeight(window.innerHeight)
        // console.log(`window.innerHeight:${window.innerHeight}, buffer:${window.innerHeight/10}`)
    }
    window.addEventListener('resize', updateDimension);


    return(() => {
        window.removeEventListener('resize', updateDimension);
    })
  }, [screenHeight])

  //Get Scroll Page Offset
  useEffect(() => {
      getPosition()      

      const onScroll = () => setOffset(window.scrollY);
      // clean up code
      window.removeEventListener('scroll', onScroll);
      window.addEventListener('scroll', onScroll, { passive: true });
      return () => window.removeEventListener('scroll', onScroll);
  }, []);

  //Update Ref Positions on window Resize and Page Load Animation
  useEffect(() => {
      window.addEventListener("resize", getPosition);
    
      setLoadingFlag(true)
      
      setTimeout(()=>{
        setLoadingFlag(false)
      },2100)
      return ()=> window.removeEventListener("resize",getPosition)
  }, []);

  //Process and update Ref for CSS
  useEffect(()=>{
    if(faqSlug!==""){
      let diff1 = faq_offset 
      let diffContact = contact_offset
      let winHeight_20= screenHeight/10
      diff1 = (faq_offset>=offset+winHeight_20?0:offset-faq_offset+winHeight_20)
      diffContact = (contact_offset>=offset+winHeight_20?0:offset-contact_offset+winHeight_20)
      setFaq_diff(diff1)
      setContact_diff(diffContact)
    }
  },[offset])
  

  return (
    <>
    {loadingFlag? <LoadingTransition />:
    <>
    {faqSlug===""? <NotFound ref={faqRef}/> :
    <>
    <motion.div className='content-container window-container' ref={faqRef}
      initial={{opacity:0}}
      animate={{opacity:1}}
      transition={{duration:0.4,delay:0.2}}
      style={{marginTop:`5vh`}}
    >
      <div className="window-content faq-window-content" >

        <h1
          style={offset>(screenHeight*3/10)?{transform:`translateY(0px)`,opacity:`0`, display:`hidden`}:{transform: `translateY(${offset*0.6}px)`, opacity:`${((screenHeight*3/10)-offset)/((screenHeight*3/10)+offset)}`}}
        >
          Know your <br/><span className={`${bannerRenderFlag?"faq-hidden":"faq-slug"}`}>{faqSlug}</span>          
        </h1>
      </div>

      <br />

      <div className="window-content"
        style={offset>(screenHeight*3/10)?{transform:`translateY(0px)`,opacity:`0`}:{transform: `translateY(${offset*0.6}px)`, opacity:`${((screenHeight*3/10)-offset)/((screenHeight*3/10)+offset)}`}}
        >
        <h3 className='faq-nav-btn'>
            {ProdCategoryList.map((item,index)=>{
              return(
                index===ProdCategoryList.length-1?
                <span key={index} 
                  
                  onClick={()=>handleKWPCatClick(item.categorySlug)}
                  >
                    <span 
                      // style={item.categorySlug===params.kwpCategory?{color:`var(--textColorContrast)`}:{}}
                      >
                    {item.categoryName}
                    </span>
                </span>
                :
                <span key={index}>
                <span  
                  // style={item.categorySlug===params.kwpCategory?{color:`var(--textColorContrast)`}:{}}    
                  onClick={()=>handleKWPCatClick(item.categorySlug)}
                  >{item.categoryName}
                </span> | </span>
              )
            }
            )}
        </h3>
      </div>
    </motion.div>

    <Outlet />

    <div className="content-container landing-container alt-container" 
        style={{backgroundColor:`transparent`}} 
        ref={contactRef}
        >
      <Contact elemDiff={contact_diff} screenHeightConst={(screenHeight/20+1)} />
    </div>
    </>
    }

    <Footer />
    </>
    }
    </>
  )
}

export default FAQ