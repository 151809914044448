import React from 'react'
import ReactWhatsapp from 'react-whatsapp'
import { FaWhatsapp } from "react-icons/fa";
import { useMediaQuery } from 'react-responsive'
import Form from "../Form/Form"
import FaqForm from '../Form/FaqForm'

function Contact({textFlag=true, whatsappFlag=true, text, elemDiff, screenHeightConst }) {
  const isSmallWindow = useMediaQuery({ query: '(max-width: 786px)' })
  const whatsappNumber = "+919685726076"

  return (
    <div className="content-grid center-cover" style={{backgroundColor:`transparent`, border:`none`}}>
          
          {textFlag?
            <article className="content-text" style={{display:`flex`, flexDirection:`column`, justifyContent:`flex-end`}}>

            <section className="small">
              <p 
              style={isSmallWindow?{textAlign:`left`,paddingLeft:`20%`, margin:`0`}:{float:`right`, textAlign:`right`, margin:`0`}}>
                Still not sure?
              </p>
            </section>
            
            <section className="big" >
              {isSmallWindow?
              <>
              <p 
              style={elemDiff>screenHeightConst?{display:`none` }:{opacity:`${(screenHeightConst-elemDiff)/screenHeightConst}`,marginBottom:`0rem`,textAlign:`left`,paddingLeft:`20%`}} 
              >
                Get in touch with
              </p>

              <p 
              style={elemDiff>screenHeightConst?{opacity:`1`,marginBottom:`0rem`,textAlign:`left`,paddingLeft:`20%`}:{display:`none`}} 
              >
                Contact
              </p>
              </>
              :
              <>
              <p 
              style={elemDiff>screenHeightConst?{display:`none` }:{opacity:`${(screenHeightConst-elemDiff)/screenHeightConst}`,float:`right`,marginBottom:`0rem`, textAlign:`right`}} 
              >
                Get in touch with
              </p>

              <p 
              style={elemDiff>screenHeightConst?{opacity:`1`,float:`right`,marginBottom:`0rem`}:{display:`none`}} 
              >
                Contact
              </p>
              </>}
            </section>

            <section className="big" >
              <p 
                style={isSmallWindow?{textAlign:`left`,paddingLeft:`20%`}:{float:`right`}} 
              >
                us
              </p>
            </section>

          </article>
          :
          <article className="content-text" style={{display:`flex`, flexDirection:`column`, justifyContent:`flex-end`}}>
            <section className="big">
              <p style={{float:`right`, alignSelf:`flex-start`, fontSize:`3rem`}}>
                {text}
              </p>
            </section>
          </article>  
        }

          <article className="content-text">
            {textFlag?<Form />:<FaqForm/>}
          </article>

          {whatsappFlag &&
          <>
          <article className="content-text">
            <section className="big" 
              >
                <p
                style={isSmallWindow?{textAlign:`center`,margin:'0'}:{float:`right`, margin:`0 0 0 1rem`, color:`var(--templateContrast)`}} 
                >
                  <ReactWhatsapp
                  className='whatsapp-chat-btn'
                  number={whatsappNumber}
                  aria-label='Whatsapp Us on '
                  message=''
                  >

                    <FaWhatsapp className='whatsapp-icon' />
                  </ReactWhatsapp>
                </p>
            </section>
          </article>

          <article className="content-text" style={{display:`flex`, flexDirection:`column`, justifyContent:`flex-end`}}>
            <section className="big">
                <p
                  style={isSmallWindow?{textAlign:`center`, margin:`0`}:{float:`left`, textAlign:`left`, margin:`0 0 0 1rem`, color:`var(--templateContrast)`}}
                  >
                ...or Whastapp Us
            </p>
            </section>
          </article>
          </>
          }

          <article className="content-text">
          <section className="medium">
              <p style={isSmallWindow?{textAlign:`center`, margin:`0`}:{float:`right`, textAlign:`right`, margin:`0`, color:`var(--templateContrast)`}}>
                <a href="mailto:neoteakindia@gmail.com">
                  neoteakindia@gmail.com
                </a>
              </p>
            </section>
          </article>

          <article className="content-text">
            <section className="medium">
              <p style={isSmallWindow?{textAlign:`center`, margin:`0`}:{float:`left`, textAlign:`left`, margin:`0 0 0 1rem`, color:`var(--templateContrast)`}}>
                <a href="tel:+917000975917">
                +91 70009 75917
                </a>
              </p>
            </section>
          </article>

  </div>
  )
}

export default Contact