import {useState, useEffect, useRef} from 'react'
import {motion} from 'framer-motion'
import "./Partners.css"
import LoadingTransition from '../../components/Loading/LoadingTransition'
import Footer from '../../components/Footer/Footer';
import Contact from '../../components/Contact/Contact';

function Partners() {

  const [offset, setOffset] = useState(0);

  const partnerRef = useRef();
  const [partner_offset, setPartner_offset] = useState()
  const [partner_diff, setPartner_diff] = useState()

  const contactRef = useRef();
  const [contact_offset, setContact_offset] = useState()
  const [contact_diff, setContact_diff] = useState()
  
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  const [loadingFlag, setLoadingFlag] = useState(false)
  
  ////////////////////////////////////
  //////////Fucntions/////////////////
  ////////////////////////////////////

  //Get Ref Positoin relative to window
  const getPosition=()=>{
    let partner = partnerRef.current.offsetTop
    let contact = contactRef.current.offsetTop
    setPartner_offset(partner)
    setContact_offset(contact)
    }

  ////////////////////////////////////
  //////////Hooks/////////////////////
  ////////////////////////////////////

  //Get Screen Height
  useEffect(() => {
      const updateDimension = () => {
          setScreenHeight(window.innerHeight)
      }
      window.addEventListener('resize', updateDimension);

      return(() => {
          window.removeEventListener('resize', updateDimension);
      })
  }, [screenHeight])

  //Get Scroll Page Offset
  useEffect(() => {
      getPosition()        

      const onScroll = () => setOffset(window.scrollY);
      // clean up code
      window.removeEventListener('scroll', onScroll);
      window.addEventListener('scroll', onScroll, { passive: true });
      return () => window.removeEventListener('scroll', onScroll);
  }, []);

  //Update Ref Positions on window Resize and Page Load Animation
  useEffect(() => {
    window.addEventListener("resize", getPosition);

    setLoadingFlag(true)
    
    setTimeout(()=>{
      setLoadingFlag(false)
    },2100)
    return ()=> window.removeEventListener("resize",getPosition)
  }, []);

  //Process and update Ref for CSS
  useEffect(()=>{
    let diff1 = partner_offset 
    let diffContact = contact_offset
    let winHeight_20= screenHeight/10
    diff1 = (partner_offset>=offset+winHeight_20?0:offset-partner_offset+winHeight_20)
    diffContact = (contact_offset>=offset+winHeight_20?0:offset-contact_offset+winHeight_20)
    setPartner_diff(diff1)
    setContact_diff(diffContact)

    // console.log(`diff:${diffContact}`)
  },[offset])
  return (
    <>
    {loadingFlag?<LoadingTransition />:
    <>
    <motion.div className='content-container window-container'ref={partnerRef}
      initial={{opacity:0}}
      animate={{opacity:1}}
      transition={{duration:0.4,delay:0.2}}
    >
      <div className="window-content" >
        <h1 
          style={offset>(screenHeight*3/10)?{transform:`translateY(0px)`,opacity:`0`}:{transform: `translateY(${offset*0.6}px)`, opacity:`${((screenHeight*3/10)-offset)/((screenHeight*3/10)+offset)}`}}
        >Our Channel Partners</h1>
      </div>
    </motion.div>

    <motion.div className="content-container landing-container"
      initial={{opacity:0, y:"50px"}}
      animate={{opacity:1, y:"0px"}}
      transition={{duration:0.5}} 
    >
     <div className="about-content-grid">
        <div className="content-img center-full-img" 
          style={{gridColumn:`1/ span 2`,borderRadius:`25px 25px 0 0`,height:`80vh`}}
          
        >
          
          <img
            style={{borderRadius:`25px 25px 0 0`, filter:`brightness(0.90)`}} 
            src="https://ik.imagekit.io/qljqw3tvn/NeoTeak/pexels-fauxels-3184418__1__mOLwy6frR.jpg" alt="bg" />
          
          <article className='img-text'>
            <section className='big-image-text'>

              <p >
                Neoteak is built upon the foundations of <span style={{fontWeight:`bold`}}>contemporary style</span>, <span style={{fontWeight:`bold`}}>sustainability</span> & <span style={{fontWeight:`bold`}}>teamwork</span>.
              </p>
              <br />
              
            </section>
          </article>
        </div>
        
      </div>

      <article className='faq-text right-side-text'>

          <section className='big-faq'>
            <p>
              It's a tradition we like to uphold.
            </p>

            {/* <p> 
              Why become a Channel Partner?
            </p> */}

          </section>

          <section className="medium-about" style={{paddingLeft:`2rem`, marginBottom:`5rem`}}>
            <br />
            <p>
              Neoteak, one of the top plywood brands in India, believes that there's only one way to success - <span style={{fontWeight:`bold`}}>through genuine collaboration</span>. With the tagline 'Ply of the New World,' we are dedicated to bringing out a range of solutions for the kitchen, bedroom and bathroom. 
            </p>

            <br />
            {/* <p>
              A high-quality resin is used in manufacturing Neoteak Plywood leading to remarkable waterproof plywood. The Neoteak Plywood range includes: Eco, Plus, Pro and more.
            </p> */}

            <br />
            <p>
              As the best plywood company, we attribute its success to the passion and loyalty of its suppliers around the country.
              In order to bring together the best plywood suppliers and to celebrate their achievements, we decided to create an exclusive club of the top dealers for Neoteak every year, shortlisted based on certain criteria.
            </p>
          </section>

      </article>
      
      <article className="faq-text right-side-text">

      

      <article className='about-text left-side-text'>
        
          <div className="content-img mag-left-img">
            <img src="https://ik.imagekit.io/qljqw3tvn/NeoTeak/pexels-lara-jameson-8828621__1__tAbC_QHhN.jpg?updatedAt=1688474911493" 
              alt=""
               />
          </div>
      </article>

      {/* <article className='about-text right-side-text' style={{border:`1px solid red`}}> */}
      

          <section className='big-faq'>

            <p> 
              From the Founder
            </p>

          </section>

          <section className="medium-about" style={{padding:`0 0 2rem 2rem`, marginBottom:`5rem`,}}>
            <br />
            <p>
              Dear Prospective Channel Partner, 
            </p>

            <br />
            <p>
            Greetings from Neoteak India!
            </p>

            <br />
            <p>
              We take immense pleasure in introducing ourselves as a company in the woodworking industry, offering an extensive range of plywood, flush doors, block boards, veneers, and laminates, with innovation at our core and quality as our cornerstone.
            </p>

            <br />
            <p>
              As a flourishing enterprise, we understand the significance of strong and symbiotic partnerships. Today, we extend our hand to you, inviting you to join us on an exciting journey of growth and prosperity.
            </p>

            <br />
            <p>
              We believe in building relationships that thrive on mutual growth and success. We are dedicated to delivering innovative, sustainable, and aesthetically pleasing solutions to our customers. We recognize the value of channel partners in reaching new markets and connecting with a diverse clientele.
            </p>

            <br />
            <p>
              By aligning with Neoteak India, you will gain access to a diverse range of premium products, each carefully crafted to meet the highest industry standards. Our extensive product portfolio, combined with competitive pricing and reliable quality, will enable you to cater to the unique needs of your customers and grow your business exponentially.
            </p>

            <br />
            <p>
              Come, be a part of the Neoteak family, and let's write a success story together!
            </p>

            <br />
            <p>
              For further inquiries and to explore this captivating partnership opportunity, please contact us. We look forward to welcoming you into the Neoteak India family.
            </p>
          </section>
      </article>
      {/* </div> */}

    </motion.div>

    <div className="content-container landing-container alt-container" 
        style={{backgroundColor:`transparent`}} 
        ref={contactRef}
        >
      <Contact elemDiff={contact_diff} screenHeightConst={(screenHeight/20+1)} />
    </div>

    <Footer />
    </>
    }
    </>
  )
}

export default Partners