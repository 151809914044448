import React from 'react'
import Footer from '../../components/Footer/Footer'

function NotFound() {
  return (
    <>
    <div className='content-container window-container' style={{height:`85vh`}}>
        {/* <div className="window-content" > */}

          <h1>404</h1>
          <br />
          <h3>Page Not Found</h3>
        {/* </div> */}

    </div>
    <Footer />  
    </>


  )
}

export default NotFound