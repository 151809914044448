import React, { useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { BsClipboard2Check, BsCheckLg } from "react-icons/bs";
import { AiOutlineColumnHeight} from "react-icons/ai";
import "../../pages/Products/Products.css"

function ProdIndex({indexList,colour}) {
    const isSmallWindow = useMediaQuery({ query: '(max-width: 786px)' }) 

    const titleList = {
        "specs":"Specifications",
        "applications":"Applications",
        "sizes":"Size and Thickness",
    }

  return (
    <>
    <div className="prod-desc-container product-spec-container" >
    {/* {for (const [key, value]))} */}
    {Object.keys(indexList).map((itemKey,index)=>{
        return (
            <React.Fragment key={index}>
            {(index%2===0 ) ?

            <React.Fragment key={index}>
                {indexList[itemKey] && 
                <div className='prod-index-grid' >
                    <div className="prod-specs">
                        {(itemKey === 'applications') &&
                            <BsCheckLg className='prod-icon check' style={{backgroundColor:colour, width:`3rem`, height:`3rem`, padding:`0.4rem`}}/>
                        }
                        {(itemKey === 'specs') &&
                            <BsClipboard2Check className='prod-icon ' style={{backgroundColor:colour, width:`3rem`, height:`3rem`, padding:`0.4rem`}}/>
                        }
                        {(itemKey === 'sizes') &&
                            <AiOutlineColumnHeight className='prod-icon' style={{backgroundColor:colour, width:`3rem`, height:`3rem`, padding:`0.4rem`}}/>
                        }
                        

                        {indexList[itemKey].list?.map((att,attIndex)=>
                            <p key={attIndex}>{att}</p>
                            )}
                    </div>

                    <div className="prod-spec-container">
                        <div className="prod-specs">
                            <p style={{fontSize:`1rem`}}>
                                {titleList[itemKey]}
                            </p>
                            <p>{indexList[itemKey].info}</p>

                        </div>
                    </div>
                    
                </div>
                }
            </React.Fragment>
            :
            <React.Fragment  key={index}>
                {indexList[itemKey] &&
                isSmallWindow?
                <div className='prod-index-grid' >
                    <div className="prod-specs">
                        {(itemKey === 'applications') &&
                            <BsCheckLg className='prod-icon check' style={{backgroundColor:colour, width:`3rem`, height:`3rem`, padding:`0.4rem`}}/>
                        }
                        {(itemKey === 'specs') &&
                            <BsClipboard2Check className='prod-icon ' style={{backgroundColor:colour, width:`3rem`, height:`3rem`, padding:`0.4rem`}}/>
                        }
                        {(itemKey === 'sizes') &&
                            <AiOutlineColumnHeight className='prod-icon' style={{backgroundColor:colour, width:`3rem`, height:`3rem`, padding:`0.4rem`}}/>
                        }
                        

                        {indexList[itemKey].list?.map((att,attIndex)=>
                            <p key={attIndex}>{att}</p>
                            )}
                    </div>

                    <div className="prod-spec-container">
                        <div className="prod-specs">
                            <p style={{fontSize:`1rem`}}>
                                {titleList[itemKey]}
                            </p>
                            <p>{indexList[itemKey].info}</p>

                        </div>
                    </div>
                    
                </div>
                : 
                <div className='prod-index-grid-alt'>
                    
                    <div className="prod-spec-container">
                        <div className="prod-specs">
                        <p style={{fontSize:`1rem`}}>
                                {titleList[itemKey]}
                            </p>
                            <p>{indexList[itemKey].info}</p>

                        </div>
                    </div>
                    <div className="prod-specs" style={{textAlign:`right`, paddingRight:`1rem`}}>
                        {itemKey === 'applications' &&
                            <BsCheckLg className='prod-icon check' style={{backgroundColor:colour, width:`3rem`, height:`3rem`, padding:`0.4rem`,justifySelf:`flex-end`}}/>
                        }
                        {itemKey === 'specs' &&
                            <BsClipboard2Check className='prod-icon ' style={{backgroundColor:colour, width:`3rem`, height:`3rem`, padding:`0.4rem`, justifySelf:`flex-end`}}/>
                        }
                        {itemKey === 'sizes' &&
                            <AiOutlineColumnHeight className='prod-icon' style={{backgroundColor:colour, width:`3rem`, height:`3rem`, padding:`0.4rem`, justifySelf:`flex-end`}}/>
                        }

                        {indexList[itemKey].list?.map((att,attIndex)=>
                            <p key={attIndex}>{att}</p>
                            )}
                    </div>

                    
                </div>
                }
            </React.Fragment>
            }
            </React.Fragment>
        )
    })
        // return (
        // index%2===0?
        // <div className='prod-index-grid' key={index}>
        //     <div className="prod-specs">
        //         <BsClipboard2Check className='prod-icon ' style={{backgroundColor:colour, width:`3rem`, height:`3rem`, padding:`0.4rem`}}/>

        //         {indexList[itemKey]?.map((att,attIndex)=>
        //             <p key={attIndex}>{att}</p>
        //             )}
        //     </div>

        //     <div className="prod-spec-container">
        //         <div className="prod-specs">
        //             <p>
        //                 {itemKey}
        //             </p>

        //         </div>
        //     </div>
            
        // </div>
        // )
        // )
    }
    </div>
    </>
  )
}

export default ProdIndex