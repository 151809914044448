import {useEffect, useRef, useState} from 'react'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom';
import { motion, useAnimation} from 'framer-motion'
import { useInView } from 'react-intersection-observer';
import "./LandingPage.css"
import Contact from '../../components/Contact/Contact';
import LoadingTransition from '../../components/Loading/LoadingTransition';
import Footer from '../../components/Footer/Footer';

function LandingPage() {
  const navigate = useNavigate();

  const isSmallWindow = useMediaQuery({ query: '(max-width: 786px)' }) 

  // Img Link and SrcSet Properties //
  const card1_imgSrc = "https://ik.imagekit.io/qljqw3tvn/tr:w-1200/NeoTeak/pexels-quintin-gellar-313773_7k5RdN7vI.jpg"
  const card1_srcSet = card1_imgSrc.substring(0,32)+"/tr:w-400"+card1_imgSrc.substring(42, card1_imgSrc.length)+" 400w,"+
                       card1_imgSrc.substring(0,32)+"/tr:w-800"+card1_imgSrc.substring(42, card1_imgSrc.length)+" 800w,"+
                       card1_imgSrc.substring(0,32)+"/tr:w-1200"+card1_imgSrc.substring(42, card1_imgSrc.length)+" 1200w,"
  
  const card2_imgSrc = "https://ik.imagekit.io/qljqw3tvn/tr:w-1200/NeoTeak/pexels-fwstudio-172292_Knm5edBmN.jpg"
  const card2_srcSet = card2_imgSrc.substring(0,32)+"/tr:w-400"+card2_imgSrc.substring(42, card2_imgSrc.length)+" 400w,"+
                       card2_imgSrc.substring(0,32)+"/tr:w-800"+card2_imgSrc.substring(42, card2_imgSrc.length)+" 800w,"+
                       card2_imgSrc.substring(0,32)+"/tr:w-1200"+card2_imgSrc.substring(42, card2_imgSrc.length)+" 1200w,"
  
  const plywoodImgSrc = "https://ik.imagekit.io/qljqw3tvn/tr:w-0800/NeoTeak/pexels-cottonbro-studio-7479035_x6mlQaVWY.jpg"
  const plywood_srcSet = plywoodImgSrc.substring(0,32)+"/tr:w-400"+plywoodImgSrc.substring(42, plywoodImgSrc.length)+" 400w,"+
                         plywoodImgSrc.substring(0,32)+"/tr:w-800"+plywoodImgSrc.substring(42, plywoodImgSrc.length)+" 800w,"+
                         plywoodImgSrc.substring(0,32)+"/tr:w-1200"+plywoodImgSrc.substring(42, plywoodImgSrc.length)+" 1200w,"

  const blockboardImgSrc = "https://ik.imagekit.io/qljqw3tvn/tr:w-0800/NeoTeak/pexels-laker-6156561_0Y1RzrYko.jpg"
  const blockboard_srcSet = blockboardImgSrc.substring(0,32)+"/tr:w-400"+blockboardImgSrc.substring(42, blockboardImgSrc.length)+" 400w,"+
                            blockboardImgSrc.substring(0,32)+"/tr:w-800"+blockboardImgSrc.substring(42, blockboardImgSrc.length)+" 800w,"+
                            blockboardImgSrc.substring(0,32)+"/tr:w-1200"+blockboardImgSrc.substring(42, blockboardImgSrc.length)+" 1200w,"

  const flushdoorImgSrc = "https://ik.imagekit.io/qljqw3tvn/tr:w-0800/NeoTeak/pexels-pixabay-277559_9gYFv40Lr.jpg"
  const flushdoor_srcSet = flushdoorImgSrc.substring(0,32)+"/tr:w-400"+flushdoorImgSrc.substring(42, flushdoorImgSrc.length)+" 400w,"+
                           flushdoorImgSrc.substring(0,32)+"/tr:w-800"+flushdoorImgSrc.substring(42, flushdoorImgSrc.length)+" 800w,"+
                           flushdoorImgSrc.substring(0,32)+"/tr:w-1200"+flushdoorImgSrc.substring(42, flushdoorImgSrc.length)+" 1200w,"

  const laminatesImgSrc = "https://ik.imagekit.io/qljqw3tvn/tr:w-0800/NeoTeak/pexels-jill-burrow-6681856_fFE3bNe7r.jpg"
  const laminates_srcSet = laminatesImgSrc.substring(0,32)+"/tr:w-400"+laminatesImgSrc.substring(42, laminatesImgSrc.length)+" 400w,"+
                           laminatesImgSrc.substring(0,32)+"/tr:w-800"+laminatesImgSrc.substring(42, laminatesImgSrc.length)+" 800w,"+
                           laminatesImgSrc.substring(0,32)+"/tr:w-1200"+laminatesImgSrc.substring(42, laminatesImgSrc.length)+" 1200w,"

  const veneerImgSrc = "https://ik.imagekit.io/qljqw3tvn/tr:w-0800/NeoTeak/wood-g84bbd7eb4_1280_suE2f41Vu.jpg"
  const veneer_srcSet = veneerImgSrc.substring(0,32)+"/tr:w-400"+veneerImgSrc.substring(42, veneerImgSrc.length)+" 400w,"+
                        veneerImgSrc.substring(0,32)+"/tr:w-800"+veneerImgSrc.substring(42, veneerImgSrc.length)+" 800w,"+
                        veneerImgSrc.substring(0,32)+"/tr:w-1200"+veneerImgSrc.substring(42, veneerImgSrc.length)+" 1200w,"
                        

  const [offset, setOffset] = useState(0);
  
  const [content1Ref, content1inView] = useInView()
  const animation1 = useAnimation();

  const [content2Ref, content2inView] = useInView({threshold:0})
  const animation2 = useAnimation();

  const [blockboardRef, blockboardinView] = useInView({threshold:0.2})
  const blockboardAnimation = useAnimation();

  const [flushdoorRef, flushdoorinView] = useInView({threshold:0.2})
  const flushdoorAnimation = useAnimation();

  const [laminatesRef, laminatesinView] = useInView({threshold:0.2})
  const laminatesAnimation = useAnimation();

  const [veneerRef, veneerinView] = useInView({threshold:0.2})
  const veneerAnimation = useAnimation();

  const win1Ref = useRef(null);
  const [win1_offset, setWin1_offset] = useState()
  const [win1_diff, setWin1_diff] = useState()

  const win2Ref = useRef(null);
  const [win2_offset, setWin2_offset] = useState()
  const [win2_diff, setWin2_diff] = useState()

  const win3Ref = useRef(null);
  const [win3_offset, setWin3_offset] = useState()
  const [win3_diff, setWin3_diff] = useState()

  // Flags //
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  const [loadingFlag, setLoadingFlag] = useState(false)


  ////////////////////////////////////
  //////////Functions/////////////////
  ////////////////////////////////////

  //Get Ref Positoin relative to window
  const getPosition=()=>{
    let win1 = win1Ref.current.offsetTop
    let win2 = win2Ref.current.offsetTop
    let win3 = win3Ref.current.offsetTop
    // console.log(JSON.stringify(win3))
    setWin1_offset(win1)
    setWin2_offset(win2)
    setWin3_offset(win3)
  }

  //Handle Navigation
  const handleProductClick=(path)=>{
    setOffset(0);
    navigate(path);
  }

  ////////////////////////////////////
  //////////Hooks/////////////////////
  ////////////////////////////////////

  //Get Screen Height
  useEffect(() => {
      const updateDimension = () => {
          setScreenHeight(window.innerHeight)
          // console.log(`window.innerHeight:${window.innerHeight}, buffer:${window.innerHeight/10}`)
      }
      window.addEventListener('resize', updateDimension);
  
  
      return(() => {
          window.removeEventListener('resize', updateDimension);
      })
  }, [screenHeight])

  //Get Scroll Page Offset
  useEffect(() => {
      getPosition()        

      const onScroll = () => setOffset(window.scrollY);
      // console.log(`scrollOffset:${window.pageYOffset}`)
      // clean up code
      window.removeEventListener('scroll', onScroll);
      window.addEventListener('scroll', onScroll, { passive: true });
      return () => window.removeEventListener('scroll', onScroll);
  }, []);

  //Update Ref Positions on window Resize and Page Load Animation
  useEffect(() => {
    window.addEventListener("resize", getPosition);

    setLoadingFlag(true)
    
    setTimeout(()=>{
      setLoadingFlag(false)
    },2100)
    return ()=> window.removeEventListener("resize",getPosition)
  }, []);

  //Process and update Ref for CSS
  useEffect(()=>{
    let diff1 = win1_offset 
    let diff2 = win2_offset
    let diff3 = win3_offset 
    let winHeight_20= screenHeight/10
    diff1 = (win1_offset>=offset?0:offset-win1_offset)
    diff2 = (win2_offset>=offset?0:offset-win2_offset)
    diff3 = (win3_offset>=offset+winHeight_20?0:offset-win3_offset+winHeight_20)
    setWin1_diff(diff1)
    setWin2_diff(diff2)
    setWin3_diff(diff3)
    // console.log(`scrollOffset:${offset}`)
  },[offset])

  //InView animation
  useEffect(()=>{
    if(content1inView){
      animation1.start("visible")
    }
    if(content2inView){
      animation2.start("visible")
    }
    if(blockboardinView){
      blockboardAnimation.start("visible")
    }
    if(flushdoorinView){
      flushdoorAnimation.start("visible")
    }
    if(laminatesinView){
      laminatesAnimation.start("visible")
    }
    if(veneerinView){
      veneerAnimation.start("visible")
    }
  },[content1inView, content2inView, blockboardinView, flushdoorinView,laminatesinView, veneerinView])

  return (
    <>
    {loadingFlag? <LoadingTransition />:
    <>
      {/* Heading 0 */}
      <motion.div className='content-container window-container'       
        initial={{opacity:0}}
        animate={{opacity:1}}
        transition={{duration:0.4,delay:0.2}} 
      >
        <div className="window-content">

          <h1 
            style={offset>(screenHeight*3/10)?{transform:`translateY(0px)`,display:`none`}:{transform: `translateY(${offset*0.6}px)`, opacity:`${((screenHeight*3/10)-offset)/((screenHeight*3/10)+offset)}`}}
          >What is Neoteak?
          </h1>
        </div>
      </motion.div>

      {/* Content 0 */}
      <motion.div className="content-container landing-container"
        initial={{opacity:0, y:"50px"}}
        animate={{opacity:1, y:"0px"}}
        transition={{duration:0.5}} 
      >
        
        
        <div className="content-grid asym-left">
          
          <div className={`content-img ${isSmallWindow?"top-img":"left-img"}`}>
            <img 
              src={card1_imgSrc} 
              srcSet={card1_srcSet}
              sizes="(max-width: 400px) 400px, (max-width: 768px) 800px, 1200px"
              alt="bg" />
          </div>

          <motion.article className='content-text'
            initial={{opacity:0,x:"-10px"}}
            animate={{opacity:1,x:0}}
            transition={{duration:0.5, delay:0.3}} 
          >
            <section className='big'>

              <p>
                Neoteak deals in a range of wood products such as Plywood, Boards, Veneers and many more.
              </p>

              <p>
                With over 55 years of experience we have our own story to share
              </p>
            </section>

            <section className='small'>

              <p>
              Neoteak operates on the motto of exemplary service backed by our long standing history in the industry.
              We aim to bring you the sustainable furniture solutions along durable quality.
                From the woods to your decor, Neoteak's products have a story of their own.
              </p>

              <div className="hover-underline-animation-btn" 
                onClick={()=>navigate("/about-us")}
              >
                {/* <a href="about-us"> */}
                Get to know us better
                {/* </a> */}
              </div>
            </section>
          </motion.article>

        </div>
        
      </motion.div>

      {/* Heading 1 */}
      <div className='content-container window-container'
        ref={win1Ref}
      >
        <div className="window-content" >

          <h1 
            style={win1_diff>(screenHeight*3/10)?{transform:`translateY(0px)`,opacity:`0`}:{transform: `translateY(${win1_diff*0.6}px)`, opacity:`${((screenHeight*3/10)-win1_diff)/((screenHeight*3/10)+win1_diff)}`}}
          >How do I know what am I looking for?</h1>
        </div>
      </div>

      {/* Content 1 */}
      <motion.div className="content-container landing-container"
        ref={content1Ref}
        variants={{
          hidden:{y:"100px",opacity:1},
          visible:{opacity:1, y:"0px", transition:{duration:0.4}},
        }}
        initial="hidden"
        // animate={content1inView?"visible":"hidden"}
        animate={animation1}
      >
        
        
        <div className="content-grid asym-right">
          

          <motion.article className='content-text'
            variants={{
              hidden:{opacity:0,x:"20px"},
              visible:{opacity:1,x:0, transition:{duration:0.5, delay:0.3}},
            }}
            initial="hidden"
            animate={animation1}
          >
            <section className='big'>

              <p>
                Finding the best Plywood for your needs can be quite a daunting task
              </p>

              <p>
                Don't worry, we have your back  
              </p>

            </section>

            <section className='small'
              style={{justifySelf:"flex-end"}}
            >

              <p>
                At Neoteak we understand how hard and confusing it can be to choose the perfect wood for your next renovation project. We just might have the right guide to help you navigate this canopy.
              </p>

              
              
                <div className="hover-underline-animation-btn" 
                  onClick={()=>navigate("/know-your-ply/plywood")}
                >
                    Know your Ply
                </div>
            </section>
          </motion.article>

          <div className={`content-img ${isSmallWindow?"bottom-img":"right-img"}`}>
            <img 
              src={card2_imgSrc} 
              srcSet={card2_srcSet}
              sizes="(max-width: 400px) 400px, (max-width: 768px) 800px, 1200px"
              alt="bg"
            />
          </div>
        
        </div>

      </motion.div>

        {/* Heading 2 */}
      <div className='content-container window-container'ref={win2Ref} >
        <div className="window-content" >

          <h1 
            style={win2_diff>(screenHeight*3/10)?{transform:`translateY(0px)`,opacity:`0`, color:`var(--textColorAlt)`, display:`hidden`}:{transform: `translateY(${win2_diff*0.6}px)`, opacity:`${((screenHeight*3/10)-win2_diff)/((screenHeight*3/10)+win2_diff)}`,color:`var(--textColorAlt)`}}
          >What does Neoteak have to offer?</h1>
        </div>
      </div>

        {/* Content 2 */}
      <motion.div className="content-container landing-container alt-container" 
        ref={content2Ref}
        variants={{
          hidden:{y:"100px",opacity:1},
          visible:{opacity:1, y:"0px", transition:{duration:0.4}},
        }}
        initial="hidden"
        animate={animation2}
      >
        <div className="content-grid center-cover">

          <div className="card">
            <motion.article className='content-text' style={isSmallWindow?{}:{gridColumn:`1/ span 2`, justifyContent:`center`, display:`flex`}}
              variants={{
                hidden:{opacity:0,x:"20px"},
                visible:{opacity:1,x:0, transition:{duration:0.5, delay:0.1}},
              }}
              initial="hidden"
              animate={animation2}
            >
              
              <section className='big' >

                <p>
                  Neoteak's Range of Products
                </p>

              </section>
            </motion.article>
          </div>

          {/* Plywood */}
          <div className='card'>

            <div className={`content-img ${isSmallWindow?"prod-center-img":"center-full-img"}`} style={{borderRadius:`0 0 0 0`}}>

              <img src={plywoodImgSrc} 
                srcSet={plywood_srcSet}
                sizes="(max-width: 400px) 400px, (max-width: 768px) 800px, 1200px"
                alt="plywood"
                style={{borderRadius:`0 0 0 0`, }}
                />
            </div>

            <motion.article className='content-text'style={{padding:`1.5rem 0 2rem 2rem`}} 
              variants={{
                hidden:{x:"20px",opacity:0},
                visible:{opacity:1, x:"0px", transition:{duration:0.5, delay:0.3}},
              }}
              initial="hidden"
              // animate={content2inView?"visible":"hidden"}
              animate={animation2}
            >
              <section className='small' ><p >Neoteak's</p></section>
              <section className='big' >

                <p>
                  Plywood
                </p>

              </section>

              <section className='small'>
                <p style={{paddingLeft:`1rem`}}>
                Being an extremely versatile product, plywood is used for a wide range of application, spanning from dinning, wardrobe, bed, table to wall panelling and shelves. We at Neoteak offer only superior quality plywood with maximum precision, uniform thickness and high density.
                </p>

                <div className="hover-underline-animation-btn alt-btn" 
                  style={{marginLeft:`1rem`}}
                  onClick={()=>handleProductClick(`products/plywood`)}
                  >
                    {/* <a href="products/plywood"> */}
                      More Info
                    {/* </a> */}
                </div>
              </section>
            
            </motion.article>
          </div>

            {/* Blockboard */}
          <div className='card' ref={blockboardRef}>
            {isSmallWindow?
            <>
              <div className="content-img prod-center-img" style={{borderRadius:`0 0 0 0`}}>

              <img src= {blockboardImgSrc}
                srcSet={blockboard_srcSet}
                sizes="(max-width: 400px) 400px, (max-width: 768px) 800px, 1200px"
                alt="bg"
                style={{borderRadius:`0 0 0 0`, }}
                />
              </div>
              <motion.article className='content-text' style={{padding:`1.5rem 0 2rem 2rem`}}
                variants={{
                  hidden:{opacity:0,x:"20px"},
                  visible:{opacity:1,x:0, transition:{duration:0.5, delay:0.2}},
                }}
                initial="hidden"
                animate={blockboardAnimation}
              >
                  <section className='small'><p>Neoteak's</p></section>
                  <section className='big'>

                    <p>
                      Block Board
                    </p>

                  </section>

                  <section className='small'>
                    <p style={{paddingLeft:`1rem`}}>
                    Blockboard is majorly used for a wide range of application, spanning from wardrobe shutters, table top doors and shelves. We at Neoteak offer only superior quality board with best quality face, 100% pine filling, triple core ply, uniform thickness and high density.
                    </p>
                    <div className="hover-underline-animation-btn alt-btn" 
                      onClick={()=>handleProductClick(`products/blockboard`)}
                      style={{marginLeft:`1rem`}}>
                      {/* <a href="products/blockboard"> */}
                        More Info
                      {/* </a> */}
                    </div>

                  </section>
                
                </motion.article>
            </>
            :
            <>

          <motion.article className='content-text' style={{padding:`1.5rem 0 2rem 2rem`}}
            variants={{
              hidden:{opacity:0,x:"20px"},
              visible:{opacity:1,x:0, transition:{duration:0.5, delay:0.2}},
            }}
            initial="hidden"
            animate={blockboardAnimation}
          >
              <section className='small'><p>Neoteak's</p></section>
              <section className='big'>

                <p>
                  Block Board
                </p>

              </section>

              <section className='small'>
                <p style={{paddingLeft:`1rem`}}>
                Blockboard is majorly used for a wide range of application, spanning from wardrobe shutters, table top doors and shelves. We at Neoteak offer only superior quality board with best quality face, 100% pine filling, triple core ply, uniform thickness and high density.
                </p>
                <div className="hover-underline-animation-btn alt-btn" 
                  onClick={()=>handleProductClick(`products/blockboard`)}
                  style={{marginLeft:`1rem`}}>
                  {/* <a href="products/blockboard"> */}
                  More Info
                  {/* </a> */}
                </div>

              </section>
            
            </motion.article>

            <div className="content-img center-full-img" style={{borderRadius:`0 0 0 0`}}>

              <img src={blockboardImgSrc} 
                srcSet={blockboard_srcSet}
                sizes="(max-width: 400px) 400px, (max-width: 768px) 800px, 1200px"
                alt="bg"
                style={{borderRadius:`0 0 0 0`, }}
                />
            </div>

            </>}
          </div>

          {/* FlushDoor */}
          <div className="card" >
            {isSmallWindow?
            <>
            <div className="content-img prod-center-img" style={{borderRadius:`0 0 0 0`}}>

            <img src={flushdoorImgSrc} 
              srcSet={flushdoor_srcSet}
              sizes="(max-width: 400px) 400px, (max-width: 768px) 800px, 1200px"
              alt="bg"
              style={{borderRadius:`0 0 0 0`}}
              />
            </div>
            
            <motion.article className='content-text' ref={flushdoorRef} style={{padding:`1.5rem 0 2rem 2rem`}}
              variants={{
                hidden:{opacity:0,x:"20px"},
                visible:{opacity:1,x:0, transition:{duration:0.5, delay:0.2}},
              }}
              initial="hidden"
              animate={flushdoorAnimation}
            >
                <section className='small' ><p>Neoteak's</p></section>
                <section className='big'>

                  <p>
                  Flush door
                  </p>

                </section>

                <section className='small'>
                  <p style={{paddingLeft:`1rem`}}>
                  Neoteak flush doors comes with strength, dimensional stability and high impact resistance to offer security, protection and privacy. Flush doors are tailor-made to suit the varying needs of the consumers, be it for residential or commercial purpose. These doors have provision for lock on both sides and the frame is made with 100% imported pine and suitable for all sorts of climatic constructions.
                  </p>
                  <div className="hover-underline-animation-btn alt-btn" 
                    onClick={()=>handleProductClick(`products/flush-door`)}
                    style={{marginLeft:`1rem`}}>
                    {/* <a href="products/flush-door"> */}
                    More Info
                    {/* </a> */}
                  </div>

                </section>
              
              </motion.article>

            </>
            :
            <>
            
            
            <motion.article className='content-text' ref={flushdoorRef} style={{padding:`1.5rem 0 2rem 2rem`}}
              variants={{
                hidden:{opacity:0,x:"20px"},
                visible:{opacity:1,x:0, transition:{duration:0.5, delay:0.2}},
              }}
              initial="hidden"
              animate={flushdoorAnimation}
            >
                <section className='small' ><p>Neoteak's</p></section>
                <section className='big'>

                  <p>
                  Flush door
                  </p>

                </section>

                <section className='small'>
                  <p style={{paddingLeft:`1rem`}}>
                  Neoteak flush doors comes with strength, dimensional stability and high impact resistance to offer security, protection and privacy. Flush doors are tailor-made to suit the varying needs of the consumers, be it for residential or commercial purpose. These doors have provision for lock on both sides and the frame is made with 100% imported pine and suitable for all sorts of climatic constructions.
                  </p>
                  <div className="hover-underline-animation-btn alt-btn" 
                    onClick={()=>handleProductClick(`products/flush-door`)}
                    style={{marginLeft:`1rem`}}>
                    {/* <a href="products/flush-door"> */}
                    More Info
                    {/* </a> */}
                  </div>

                </section>
              
              </motion.article>

            <div className="content-img center-full-img" style={{borderRadius:`0 0 0 0`}}>

              <img src={flushdoorImgSrc}
                srcSet={flushdoor_srcSet} 
                sizes="(max-width: 400px) 400px, (max-width: 768px) 800px, 1200px"
                alt="bg"
                style={{borderRadius:`0 0 0 0`}}
                />
            </div>
            </>  
            }
          </div>

          {/* Laminates */}
          <div className="card" 
            
            style={isSmallWindow?{}:{gridColumn:`1/ span 2`, height:`60vh`}}
          >
            
            <div className={`content-img ${isSmallWindow?"prod-center-img":"center-full-img"}`} style={isSmallWindow?{}:{borderRadius:`0 0 0 0`,minHeight:`60vh`}}>
              <img src={laminatesImgSrc}
                srcSet={laminates_srcSet} 
                sizes="(max-width: 400px) 400px, (max-width: 768px) 800px, 1200px"
                alt="laminates"
                style={isSmallWindow?{}:{borderRadius:`0 0 0 0`, borderBottom:`2px solid var(--textColorAlt)`}}
                />
            </div>

            <motion.article className='content-text' 
              ref={laminatesRef}
              style={{padding:`1.5rem 0 2rem 2rem`}}
              variants={{
                hidden:{opacity:0,x:"20px"},
                visible:{opacity:1,x:0, transition:{duration:0.5, delay:0.1}},
              }}
              initial="hidden"
              animate={laminatesAnimation}
            >
                <section className='small' ><p>Neoteak's</p></section>
                <section className='big' >

                  <p>
                  Laminates
                  </p>

                </section>

                <section className='small'>
                  <p style={{padding:`1rem`}}>
                  We, at Neoteak, are committed to designing innovative decorative finishes that enhance spaces in which they are found. Whether striking high gloss, bright solid colours, metals or realistic woods, Veneer finish each design is hand picked and papers are imported from all over the world.
                  </p>
                  <div className="hover-underline-animation-btn alt-btn" 
                    onClick={()=>handleProductClick(`products/laminates`)}
                    style={{marginLeft:`1rem`}}>
                    {/* <a href="products/laminates"> */}
                      More Info
                    {/* </a> */}
                  </div>
                </section>
              
              </motion.article>

            
          </div>

          {/* Veneer */}
          <div className="card" 
            style={isSmallWindow?{}:{gridColumn:`1/ span 2`, height:`60vh`}}
            
          >
            {isSmallWindow?
              <>
              <div className="content-img prod-center-img">
                <img src= {veneerImgSrc}
                  srcSet={veneer_srcSet}
                  sizes="(max-width: 400px) 400px, (max-width: 768px) 800px, 1200px"
                  alt="bg"
                  />
              </div>

              <motion.article className='content-text' 
                ref={veneerRef}
                style={{padding:`1.5rem 0 2rem 2rem`}}
                variants={{
                  hidden:{opacity:0,x:"20px"},
                  visible:{opacity:1,x:0, transition:{duration:0.5, delay:0.1}},
                }}
                initial="hidden"
                animate={veneerAnimation}
              >
                <section className='small'><p >Neoteak's</p></section>
                <section className='big'>

                  <p>
                  Veneer
                  </p>

                </section>

                <section className='small'>
                  <p style={{padding:`1rem`}}>
                  Neoteak veneers are made of exotic species specially handpicked from across the globe such as America, Australia, Brazil, Europe, and Africa adding the beauty of wood to your furniture. Our veneers are made with superior quality base plywood and full thickness of front face.a
                  </p>

                  <div className="hover-underline-animation-btn alt-btn" 
                    onClick={()=>handleProductClick(`products/veneer`)}
                    style={{marginLeft:`1rem`}}>
                      {/* <a href="products/veneer"> */}
                      More Info
                      {/* </a> */}
                    </div>  
                </section>
                
              </motion.article>
              </>
              :
              <>
              <motion.article className='content-text' 
                ref={veneerRef}
                style={{padding:`1.5rem 0 2rem 2rem`}}
                variants={{
                  hidden:{opacity:0,x:"20px"},
                  visible:{opacity:1,x:0, transition:{duration:0.5, delay:0.1}},
                }}
                initial="hidden"
                animate={veneerAnimation}
              >
                <section className='small'><p >Neoteak's</p></section>
                <section className='big'>

                  <p>
                  Veneer
                  </p>

                </section>

                <section className='small'>
                  <p style={{padding:`1rem`}}>
                  Neoteak veneers are made of exotic species specially handpicked from across the globe such as America, Australia, Brazil, Europe, and Africa adding the beauty of wood to your furniture. Our veneers are made with superior quality base plywood and full thickness of front face.a
                  </p>

                  <div className="hover-underline-animation-btn alt-btn" 
                    onClick={()=>handleProductClick(`products/veneer`)}
                    style={{marginLeft:`1rem`}}>
                      {/* <a href="products/veneer"> */}
                      More Info
                      {/* </a> */}
                    </div>  
                </section>
                
              </motion.article>

                <div className="content-img center-full-img" style={{borderRadius:`0 0 0 0`,minHeight:`60vh`}}>
                  <img src={veneerImgSrc} 
                    srcSet={veneer_srcSet}
                    sizes="(max-width: 400px) 400px, (max-width: 768px) 800px, 1200px"
                    alt="veneer"
                    style={{borderRadius:`0 0 0 0`, borderBottom:`2px solid var(--textColorAlt)`}}
                    />
                </div>

              </>
            }
          </div>

        </div>

      </motion.div>

        {/* Content4 */}

      <div className="content-container landing-container alt-container" 
        style={{backgroundColor:`transparent`}} 
        ref={win3Ref}
        >
          <Contact elemDiff={win3_diff} screenHeightConst={(screenHeight/20+1)} />
      </div>

      <Footer />

    </>
    }
    </>
  )
}

export default LandingPage