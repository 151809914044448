import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom';
import "./Footer.css"
// import Form from '../Form/Form';

function Footer() {
  const isSmallWindow = useMediaQuery({ query: '(max-width: 768px)' }) 

  const iconLink = isSmallWindow?"https://ik.imagekit.io/qljqw3tvn/NeoTeak/Neoteak_Logo_Colour_k1-WfXXH6.png":"https://ik.imagekit.io/qljqw3tvn/NeoTeak/NeoTeak_logo-removebg-preview_BiW_xP3sS.png?updatedAt=1682601496023"

  const navigate = useNavigate()

  return (
    <div className='footer-container'>
      <div className="footer-content">
        
        <div className="footer-content-item logo">
          {!isSmallWindow && <h1
          style={{fontFamily: "Sabon", fontWeight:`normal`}}
            onClick={()=>navigate("/")}
          >Neoteak</h1>}
          
          <img className='footer-logo'
            src="https://ik.imagekit.io/qljqw3tvn/NeoTeak/Neoteak_Logo_Line_urwFMVvPc7.png" 
            alt=""
            onClick={()=>navigate("/")}
          />

        </div>

        <div className='footer-content-item center'>
          {/* <Form textColor={"white"}/> */}
          <article>
            <section>
              <p>
                <a href="mailto:neoteakindia@gmail.com" aria-label='Email us at neoteakindia@gmail.com' style={{color:`white`}}>
                  neoteakindia@gmail.com
                </a>
              </p>
              </section>

              <section >
              <p>
                <a href="tel:+917000975917" aria-label='Call us on +917000975917' style={{color:`white`}}>
                +91 70009 75917
                </a>
              </p>
            </section>
          </article>
        </div>

        <div className='footer-content-item shortcuts'>
          <div className="shortcuts-list">

              {/* <h3>Home</h3> */}
              {/* <h3>About Us</h3>
              <h3>Know Your Ply</h3>
              <h3>Products</h3>
              <h3>Partners</h3> */}
              <h3 onClick={()=>navigate("/")}>
                Home
              </h3>
              
              <h3 onClick={()=>navigate("/about-us")}>
                About Us
              </h3>
              
              <h3 onClick={()=>navigate("/products/plywood")}>
                Products
              </h3>
              
              <h3 onClick={()=>navigate("/know-your-ply/plywood")}>
                Know Your Ply
              </h3>

              <h3 onClick={()=>navigate("/channel-partners")}>
                Partners
              </h3>

              <h3 >
                Whatsapp
              </h3>

              {/* <h3>
                IndiaMart
              </h3> */}
            
          </div>
        </div>
      </div>
      
      <div className="copyright-container">

        © 2023 by Neoteak.
      </div>
    </div>
  )
}

export default Footer